/* Bootstrap setup */
/* Muze setup */
/* Muze Variables */
/* Muze Color Variables */
/* Muze Font Weight Variables */
/* Muze Transition Variables */
/* Bootstrap core */
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
:root {
  --bs-blue: #0da2f5;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0da2f5;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8fcfc;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Inter, sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0da2f5;
  text-decoration: underline;
}

a:hover {
  color: #0da2f5;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row > * {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
}

.row-cols-auto > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 980px) {
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8fcfc;
  --bs-table-striped-bg: #ecefef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe3e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e9e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe3e3;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  -moz-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  -moz-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  -moz-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}

textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}

textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0da2f5;
  border-color: #0da2f5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
  line-height: 1;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0da2f5;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0da2f5;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
    -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus,
.input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus,
.input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0a7ab8;
  border-color: #0a7ab8;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0da2f5;
  border-color: #0a53be;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light-selected {
  color: #667085;
  background-color: #fafafa;
  border-color: #fafafa;
}

.btn-light-selected:hover {
  color: #000;
  background-color: #ededed;
  border-color: #ededed;
}

.btn-light {
  color: #000;
  background-color: #f8fcfc;
  border-color: #f8fcfc;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fcfc;
  border-color: #f9fcfc;
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #f9fcfc;
  border-color: #f9fcfc;
  box-shadow: 0 0 0 0.25rem rgba(211, 214, 214, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fdfd;
  border-color: #f9fcfc;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 214, 214, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #f8fcfc;
  border-color: #f8fcfc;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0da2f5;
  border-color: #0da2f5;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0a7ab8 !important;
  border-color: #0a7ab8 !important;
}

.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #0da2f5;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8fcfc;
  border-color: #f8fcfc;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8fcfc;
  border-color: #f8fcfc;
}

.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 252, 252, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8fcfc;
  border-color: #f8fcfc;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 252, 252, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f8fcfc;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: normal;
  color: #0da2f5;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0da2f5;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  width: 100%;
  white-space: nowrap;
  justify-content: space-between !important;
  height: 48px !important;
  color: black !important;
  background-color: white !important;
  border-color: #ced4da !important;
  font-weight: normal !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  width: 100%;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0da2f5;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0da2f5;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0da2f5;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: #0da2f5;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0da2f5;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  font-weight: bold;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  color: #212529 !important;
  text-align: left;
  background-color: #f6fbff !important;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion-button::after {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border: none !important;
  outline: 0;
  box-shadow: none !important;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: none;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 16px 0px;
  background-color: #f6fbff;
  font-size: 14px;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0da2f5;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0da2f5;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0da2f5;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636565;
  background-color: #fefefe;
  border-color: #fdfefe;
}

.alert-light .alert-link {
  color: #4f5151;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0da2f5;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636565;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #636565;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636565;
  border-color: #636565;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  color: #0da2f5;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0da2f5;
}

.link-primary:hover,
.link-primary:focus {
  color: #0da2f5;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:hover,
.link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover,
.link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover,
.link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover,
.link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8fcfc;
}

.link-light:hover,
.link-light:focus {
  color: #f9fdfd;
}

.link-dark {
  color: #212529;
}

.link-dark:hover,
.link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0da2f5 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8fcfc !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.justify-content-evenly {
  -ms-flex-pack: space-evenly !important;
  justify-content: space-evenly !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.order-first {
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.order-0 {
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

.order-1 {
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.order-2 {
  -ms-flex-order: 2 !important;
  order: 2 !important;
}

.order-3 {
  -ms-flex-order: 3 !important;
  order: 3 !important;
}

.order-4 {
  -ms-flex-order: 4 !important;
  order: 4 !important;
}

.order-5 {
  -ms-flex-order: 5 !important;
  order: 5 !important;
}

.order-last {
  -ms-flex-order: 6 !important;
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: normal !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0da2f5 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8fcfc !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0da2f5 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8fcfc !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-sm-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-sm-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-sm-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-sm-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-sm-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-sm-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-sm-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-sm-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-md-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-md-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-md-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-md-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-md-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-md-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-md-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-md-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-lg-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-lg-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-lg-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-lg-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-lg-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-lg-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-lg-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-lg-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xxl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    -ms-flex-pack: space-evenly !important;
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  .order-xxl-first {
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }
  .order-xxl-0 {
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
  .order-xxl-1 {
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }
  .order-xxl-2 {
    -ms-flex-order: 2 !important;
    order: 2 !important;
  }
  .order-xxl-3 {
    -ms-flex-order: 3 !important;
    order: 3 !important;
  }
  .order-xxl-4 {
    -ms-flex-order: 4 !important;
    order: 4 !important;
  }
  .order-xxl-5 {
    -ms-flex-order: 5 !important;
    order: 5 !important;
  }
  .order-xxl-last {
    -ms-flex-order: 6 !important;
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/* Muze Basic Font Import */
/* Muze Color Variables */
/*
1.  Muze Normal CSS
2.  Muze Components CSS
    2.  1.  Muze Colors CSS
    2.  1.  1. Muze Selection Color CSS
    2.  1.  2. Muze Background Color CSS
    2.  1.  3. Muze Color CSS
    2.  1.  4. Muze Border Color CSS
    2.  2.  Muze Font Family CSS
    2.  3.  Muze Font Weights CSS
    2.  4.  Muze Font Sizes CSS
    2.  5.  Muze After CSS
    2.  6.  Muze Shapes CSS
    2.  7.  Muze Box Shadow CSS
    2.  8.  Muze Typography Page CSS
    2.  9.  Muze Avaters CSS
    2.  10.  Muze Button CSS
    2.  11.  Muze Badge CSS
    2.  12.  Muze Cards CSS
    2.  13.  Muze Alerts CSS
    2.  14.  Muze Checks CSS
    2.  15.  Muze Navbars CSS
    2.  16.  Muze Popovers CSS
    2.  17.  Muze Transition CSS
    2.  18.  Muze Multiple Select CSS
    2.  19.  Muze Overflow CSS
    2.  20.  Muze Progress Bar CSS
    2.  21.  Muze Circle CSS
    2.  22.  Muze Tabs CSS
    2.  22.  1.  Muze Tabs Gray CSS
    2.  23.  Muze Swiper Slider CSS
    2.  24.  Muze Tabler Icons CSS
    2.  25.  Muze Modal Popup CSS
    2.  26.  Muze Table CSS
    2.  27.  Muze List Group CSS
    2.  28.  Muze Btn Close CSS
    2.  29.  Muze Opacity CSS
    2.  30.  Muze Position CSS
    2.  31.  Muze Z-index CSS
  3. Muze Links Page CSS
  4. Muze Dropzone Page CSS
*/
/* 1. Muze Normal CSS */
body {
  color: #1a1919;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
}

*:focus {
  outline: none;
}

a {
  text-decoration: none;
  transition: all ease 0.4s;
}

a:focus {
  outline: none;
  outline-offset: 0;
}

a:hover,
a:not(.btn):focus {
  text-decoration: none;
  color: #0da2f5;
}

b,
strong,
.dropdown-menu span.strong {
  font-weight: 700;
}

p {
  line-height: 1.75rem;
}

img {
  max-width: 100%;
  transition: all ease 0.4s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

iframe {
  display: block;
  width: 100%;
  height: auto;
  border: none;
}

video {
  display: block;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  background: #f8fcfc;
}

audio,
canvas,
progress,
video {
  vertical-align: middle;
}

button {
  outline: none;
  outline-offset: 0;
  transition: all ease 0.4s;
}

button:focus {
  outline: none;
  outline-offset: 0;
  transition: all ease 0.4s;
}

label {
  cursor: pointer;
}

label.disable {
  cursor: none;
  opacity: 0.3;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

sup {
  vertical-align: super;
  font-size: 0.27em;
}

.has-unalignment-cards [class*="col-"] .card {
  height: auto;
}

[data-highcharts-chart] {
  width: 100%;
}

.vertical-none {
  vertical-align: unset;
}

.mw-auto {
  max-width: none;
}

.direction-rtl {
  direction: rtl;
}

/* 2. Muze Components CSS */
/* 2. 1. Muze Colors CSS */
/* 2. 1. 1. Muze Selection Color CSS  */
::selection,
::-webkit-selection,
::-moz-selection,
::-o-selection,
::-ms-selection {
  background: #212529;
  color: #fff;
}

select::-ms-expand {
  display: none;
}

/* 2.  1. 2. Muze Background Color CSS */
.link-dark {
  color: #1e1e1e;
}

.link-dark:hover {
  color: #495057;
}

.link-dark:hover svg path {
  fill: #495057;
}

.link-dark svg path {
  fill: #1e1e1e;
}

.link-primary:hover svg path {
  fill: #0da2f5;
}

.link-primary svg path {
  fill: #0da2f5;
}

.bg-secondary {
  background-color: #495057 !important;
}

.bg-success {
  background-color: #4dd4ac !important;
}

.bg-danger {
  background-color: #e35d6a !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-lighter-light {
  background-color: #f0f0f0;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-ornage-150 {
  background-color: #ffead8 !important;
}

.btn-soft-primary {
  background-color: #e6f0ff;
}

.btn-soft-primary:hover {
  background-color: #0da2f5 !important;
  color: #fff;
}

.btn-soft-primary:hover svg path {
  fill: #fff;
}

.bg-dnd {
  background-color: #e25563 !important;
}

.bg-away {
  background-color: #fd933a !important;
}

.btn-secondary {
  background-color: #495057 !important;
  border-color: #495057 !important;
}

.btn-success {
  background-color: #4dd4ac !important;
  border-color: #4dd4ac !important;
}

.btn-success:hover {
  background-color: #146c43 !important;
  border-color: #146c43 !important;
}

.btn-danger {
  background-color: #e35d6a !important;
  border-color: #e35d6a !important;
}

.btn-danger:hover {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.btn-warning {
  color: #1e1e1e;
}

.btn-info {
  color: #fff;
}

.btn-light {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #1e1e1e;
}

.btn-dark {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
}

.btn-dark:hover {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.btn-dark-100 {
  background-color: #f8f9fa;
  color: #6c757d;
}

.btn-dark-100:hover,
.btn-dark-100:focus {
  background-color: #e6f0ff;
  color: #0da2f5;
}

.btn-dark-100:hover svg path,
.btn-dark-100:focus svg path {
  fill: #0da2f5;
}

.btn-soft-secondary {
  background-color: rgba(73, 80, 87, 0.1);
  color: #495057;
}

.btn-soft-success {
  background-color: rgba(77, 212, 172, 0.1);
  color: #4dd4ac;
}

.btn-soft-danger {
  background-color: rgba(227, 93, 106, 0.1);
  color: #e35d6a;
}

.btn-soft-warning {
  background-color: rgba(255, 193, 7, 0.1);
  color: #212529;
}

.btn-soft-info {
  background-color: rgba(13, 202, 240, 0.1);
  color: #0dcaf0;
}

.btn-soft-light {
  background-color: rgba(248, 252, 252, 0.1);
}

.btn-soft-dark {
  background-color: rgba(33, 37, 41, 0.1);
}

.btn-outline-secondary {
  border-color: #495057;
  color: #495057;
}

.btn-outline-success {
  border-color: #4dd4ac;
  color: #4dd4ac;
}

.btn-outline-danger {
  border-color: #e35d6a;
  color: #e35d6a;
}

.btn-outline-warning {
  border-color: #ffc107;
  color: #ffc107;
}

.btn-outline-info {
  border-color: #0dcaf0;
  color: #0dcaf0;
}

.btn-outline-light {
  border-color: #f8fcfc;
  color: #f8fcfc;
}

.btn-outline-dark {
  border-color: #212529 !important;
  color: #212529 !important;
}

.bg-purple-light {
  background-color: #e4d3ff;
}

.bg-purple-80 {
  background-color: #efe5ff;
}

.bg-green-light {
  background-color: #acffd3;
}

.bg-green-80 {
  background-color: #d3ffe7;
}

.bg-orange-light {
  background-color: #ffe4cd;
}

.bg-orange-80 {
  background-color: #ffedde;
}

.bg-black-800 {
  background-color: #121111;
}

.bg-black-600 {
  background-color: #1e1e1e;
}

.text-black-800 {
  color: #121111;
}

.text-black-600 {
  color: #1e1e1e;
}

.text-gray-150 {
  color: #adb5bd;
}

.text-gray-600 {
  color: #6c757d !important;
}

.text-gray-700 {
  color: #495057 !important;
}

.text-orange-500 {
  color: #fd7e14;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.green-hover:hover {
  background-color: #4dd4ac;
}

.green-hover:hover svg path {
  fill: #fff;
}

.icon-blue-hover > a:hover svg path,
.icon-blue-hover > .dropdown > a:hover svg path {
  fill: #0da2f5;
}

.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.bg-gray-50 {
  background-color: #e9ecef !important;
}

.bg-gray-100 {
  background-color: #f8f9fa !important;
}

.bg-gray-200 {
  background-color: #e9ecef;
}

.bg-gray-300 {
  background-color: #dee2e6;
}

.bg-gray-400 {
  background-color: #ced4da;
}

.bg-gray-500 {
  background-color: #adb5bd;
}

.bg-gray-600 {
  background-color: #6c757d;
}

.bg-gray-700 {
  background-color: #495057;
}

.bg-gray-800 {
  background-color: #343a40;
}

.bg-gray-900 {
  background-color: #212529;
}

.bg-blue-50 {
  background-color: #e6f0ff;
}

.bg-blue-100 {
  background-color: #cfe2ff;
}

.bg-blue-200 {
  background-color: #9ec5fe;
}

.bg-blue-300 {
  background-color: #6ea8fe;
}

.bg-blue-400 {
  background-color: #3d8bfd;
}

.bg-blue-500 {
  background-color: #0da2f5;
}

.bg-blue-600 {
  background-color: #0da2f5;
}

.bg-blue-700 {
  background-color: #084298;
}

.bg-blue-800 {
  background-color: #052c65;
}

.bg-blue-900 {
  background-color: #031633;
}

.bg-indigo-50 {
  background-color: #e8ddfd;
}

.bg-indigo-100 {
  background-color: #e0cffc;
}

.bg-indigo-200 {
  background-color: #c29ffa;
}

.bg-indigo-300 {
  background-color: #a370f7;
}

.bg-indigo-400 {
  background-color: #8540f5;
}

.bg-indigo-500 {
  background-color: #6610f2;
}

.bg-indigo-600 {
  background-color: #520dc2;
}

.bg-indigo-700 {
  background-color: #3d0a91;
}

.bg-indigo-800 {
  background-color: #290661;
}

.bg-indigo-900 {
  background-color: #140330;
}

.bg-indigo-light {
  background-color: #975cf6;
}

.bg-purple-50 {
  background-color: #e8ddff;
}

.bg-purple-100 {
  background-color: #e2d9f3;
}

.bg-purple-200 {
  background-color: #c5b3e6;
}

.bg-purple-300 {
  background-color: #a98eda;
}

.bg-purple-400 {
  background-color: #8c68cd;
}

.bg-purple-500 {
  background-color: #6f42c1;
}

.bg-purple-600 {
  background-color: #59359a;
}

.bg-purple-700 {
  background-color: #432874;
}

.bg-purple-800 {
  background-color: #2c1a4d;
}

.bg-purple-900 {
  background-color: #160d27;
}

.bg-pink-50 {
  background-color: #ffddee;
}

.bg-pink-100 {
  background-color: #f7d6e6;
}

.bg-pink-200 {
  background-color: #efadce;
}

.bg-pink-300 {
  background-color: #e685b5;
}

.bg-pink-400 {
  background-color: #de5c9d;
}

.bg-pink-500 {
  background-color: #d63384;
}

.bg-pink-600 {
  background-color: #ab296a;
}

.bg-pink-700 {
  background-color: #801f4f;
}

.bg-pink-800 {
  background-color: #561435;
}

.bg-pink-900 {
  background-color: #2b0a1a;
}

.bg-red-50 {
  background-color: #ffe1e3;
}

.bg-red-100 {
  background-color: #f8d7da;
}

.bg-red-200 {
  background-color: #f1aeb5;
}

.bg-red-300 {
  background-color: #ea868f;
}

.bg-red-400 {
  background-color: #e35d6a;
}

.bg-red-500 {
  background-color: #dc3545;
}

.bg-red-600 {
  background-color: #b02a37;
}

.bg-red-700 {
  background-color: #842029;
}

.bg-red-800 {
  background-color: #58151c;
}

.bg-red-900 {
  background-color: #2c0b0e;
}

.bg-orange-50 {
  background-color: #ffead7;
}

.bg-orange-100 {
  background-color: #ffe5d0;
}

.bg-orange-200 {
  background-color: #fecba1;
}

.bg-orange-300 {
  background-color: #feb272;
}

.bg-orange-400 {
  background-color: #fd9843;
}

.bg-orange-500 {
  background-color: #fd7e14;
}

.bg-orange-600 {
  background-color: #ca6510;
}

.bg-orange-700 {
  background-color: #984c0c;
}

.bg-orange-800 {
  background-color: #653208;
}

.bg-orange-900 {
  background-color: #331904;
}

.bg-yellow-50 {
  background-color: #faf0cd;
}

.bg-yellow-100 {
  background-color: #fff3cd;
}

.bg-yellow-200 {
  background-color: #ffe69c;
}

.bg-yellow-300 {
  background-color: #ffda6a;
}

.bg-yellow-400 {
  background-color: #ffcd39;
}

.bg-yellow-500 {
  background-color: #ffc107;
}

.bg-yellow-600 {
  background-color: #cc9a06;
}

.bg-yellow-700 {
  background-color: #997404;
}

.bg-yellow-800 {
  background-color: #664d03;
}

.bg-yellow-900 {
  background-color: #332701;
}

.bg-green-50 {
  background-color: #ddf4e2;
}

.bg-green-100 {
  background-color: #d1e7dd;
}

.bg-green-200 {
  background-color: #a3cfbb;
}

.bg-green-300 {
  background-color: #75b798;
}

.bg-green-400 {
  background-color: #479f76;
}

.bg-green-500 {
  background-color: #198754;
}

.bg-green-600 {
  background-color: #146c43;
}

.bg-green-700 {
  background-color: #0f5132;
}

.bg-green-800 {
  background-color: #0a3622;
}

.bg-green-900 {
  background-color: #051b11;
}

.bg-teal-50 {
  background-color: #dcfff5;
}

.bg-teal-100 {
  background-color: #d2f4ea;
}

.bg-teal-200 {
  background-color: #a6e9d5;
}

.bg-teal-300 {
  background-color: #79dfc1;
}

.bg-teal-400 {
  background-color: #4dd4ac;
}

.bg-teal-500 {
  background-color: #20c997;
}

.bg-teal-600 {
  background-color: #1aa179;
}

.bg-teal-700 {
  background-color: #13795b;
}

.bg-teal-800 {
  background-color: #0d503c;
}

.bg-teal-900 {
  background-color: #06281e;
}

.bg-cyan-50 {
  background-color: #dafbff;
}

.bg-cyan-100 {
  background-color: #cff4fc;
}

.bg-cyan-200 {
  background-color: #9eeaf9;
}

.bg-cyan-300 {
  background-color: #6edff6;
}

.bg-cyan-400 {
  background-color: #3dd5f3;
}

.bg-cyan-500 {
  background-color: #0dcaf0;
}

.bg-cyan-600 {
  background-color: #0aa2c0;
}

.bg-cyan-700 {
  background-color: #087990;
}

.bg-cyan-800 {
  background-color: #055160;
}

.bg-cyan-900 {
  background-color: #032830;
}

.bg-black {
  background-color: #000;
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:hover {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.bg-light-100 {
  background-color: #f3f3f3;
}

/* 2.  1. 3. Muze Color CSS */
.text-muted {
  color: #adb5bd !important;
}

.text-black {
  color: #000;
}

.blue-dark {
  color: #495057;
}

.link-success {
  color: #4dd4ac;
}

.btn-soft-primary {
  color: #0da2f5;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  color: #1e1e1e;
}

.text-secondary {
  color: #495057 !important;
}

.text-danger {
  color: #e35d6a !important;
}

.text-success {
  color: #4dd4ac !important;
}

.text-blue-600 {
  color: #0da2f5;
}

.text-warning {
  color: #ffc107 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-light {
  color: #f8fcfc !important;
}

.text-dark {
  color: #212529 !important;
}

.text-teal-500 {
  color: #20c997;
}

.text-dnd {
  color: #e25563;
}

/* 2.  1.  4. Muze Border Color CSS */
hr {
  border-color: #dee2e6;
}

.border-black {
  border-color: #000 !important;
}

.border-secondary {
  border-color: #495057 !important;
}

.border-success {
  border-color: #4dd4ac !important;
}

.border-danger {
  border-color: #e35d6a !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-gray-50 {
  border-color: #e9ecef !important;
}

.border-gray-100 {
  border-color: #f8f9fa !important;
}

.border-gray-200 {
  border-color: #e9ecef !important;
}

.border-gray-300 {
  border-color: #dee2e6 !important;
}

.border-gray-400 {
  border-color: #ced4da !important;
}

.border-gray-500 {
  border-color: #adb5bd !important;
}

.border-gray-600 {
  border-color: #6c757d !important;
}

.border-gray-700 {
  border-color: #495057 !important;
}

.border-gray-800 {
  border-color: #343a40 !important;
}

.border-gray-900 {
  border-color: #212529 !important;
}

.border-blue-50 {
  border-color: #e6f0ff !important;
}

.border-blue-100 {
  border-color: #cfe2ff !important;
}

.border-blue-200 {
  border-color: #9ec5fe !important;
}

.border-blue-300 {
  border-color: #6ea8fe !important;
}

.border-blue-400 {
  border-color: #3d8bfd !important;
}

.border-blue-500 {
  border-color: #0da2f5 !important;
}

.border-blue-600 {
  border-color: #0da2f5 !important;
}

.border-blue-700 {
  border-color: #084298 !important;
}

.border-blue-800 {
  border-color: #052c65 !important;
}

.border-blue-900 {
  border-color: #031633 !important;
}

.border-indigo-50 {
  border-color: #e8ddfd !important;
}

.border-indigo-100 {
  border-color: #e0cffc !important;
}

.border-indigo-200 {
  border-color: #c29ffa !important;
}

.border-indigo-300 {
  border-color: #a370f7 !important;
}

.border-indigo-400 {
  border-color: #8540f5 !important;
}

.border-indigo-500 {
  border-color: #6610f2 !important;
}

.border-indigo-600 {
  border-color: #520dc2 !important;
}

.border-indigo-700 {
  border-color: #3d0a91 !important;
}

.border-indigo-800 {
  border-color: #290661 !important;
}

.border-indigo-900 {
  border-color: #140330 !important;
}

.border-purple-50 {
  border-color: #e8ddff !important;
}

.border-purple-100 {
  border-color: #e2d9f3 !important;
}

.border-purple-200 {
  border-color: #c5b3e6 !important;
}

.border-purple-300 {
  border-color: #a98eda !important;
}

.border-purple-400 {
  border-color: #8c68cd !important;
}

.border-purple-500 {
  border-color: #6f42c1 !important;
}

.border-purple-600 {
  border-color: #59359a !important;
}

.border-purple-700 {
  border-color: #432874 !important;
}

.border-purple-800 {
  border-color: #2c1a4d !important;
}

.border-purple-900 {
  border-color: #160d27 !important;
}

.border-pink-50 {
  border-color: #ffddee !important;
}

.border-pink-100 {
  border-color: #f7d6e6 !important;
}

.border-pink-200 {
  border-color: #efadce !important;
}

.border-pink-300 {
  border-color: #e685b5 !important;
}

.border-pink-400 {
  border-color: #de5c9d !important;
}

.border-pink-500 {
  border-color: #d63384 !important;
}

.border-pink-600 {
  border-color: #ab296a !important;
}

.border-pink-700 {
  border-color: #801f4f !important;
}

.border-pink-800 {
  border-color: #561435 !important;
}

.border-pink-900 {
  border-color: #2b0a1a !important;
}

.border-red-50 {
  border-color: #ffe1e3 !important;
}

.border-red-100 {
  border-color: #f8d7da !important;
}

.border-red-200 {
  border-color: #f1aeb5 !important;
}

.border-red-300 {
  border-color: #ea868f !important;
}

.border-red-400 {
  border-color: #e35d6a !important;
}

.border-red-500 {
  border-color: #dc3545 !important;
}

.border-red-600 {
  border-color: #b02a37 !important;
}

.border-red-700 {
  border-color: #842029 !important;
}

.border-red-800 {
  border-color: #58151c !important;
}

.border-red-900 {
  border-color: #2c0b0e !important;
}

.border-orange-50 {
  border-color: #ffead7 !important;
}

.border-orange-100 {
  border-color: #ffe5d0 !important;
}

.border-orange-200 {
  border-color: #fecba1 !important;
}

.border-orange-300 {
  border-color: #feb272 !important;
}

.border-orange-400 {
  border-color: #fd9843 !important;
}

.border-orange-500 {
  border-color: #fd7e14 !important;
}

.border-orange-600 {
  border-color: #ca6510 !important;
}

.border-orange-700 {
  border-color: #984c0c !important;
}

.border-orange-800 {
  border-color: #653208 !important;
}

.border-orange-900 {
  border-color: #331904 !important;
}

.border-yellow-50 {
  border-color: #faf0cd !important;
}

.border-yellow-100 {
  border-color: #fff3cd !important;
}

.border-yellow-200 {
  border-color: #ffe69c !important;
}

.border-yellow-300 {
  border-color: #ffda6a !important;
}

.border-yellow-400 {
  border-color: #ffcd39 !important;
}

.border-yellow-500 {
  border-color: #ffc107 !important;
}

.border-yellow-600 {
  border-color: #cc9a06 !important;
}

.border-yellow-700 {
  border-color: #997404 !important;
}

.border-yellow-800 {
  border-color: #664d03 !important;
}

.border-yellow-900 {
  border-color: #332701 !important;
}

.border-green-50 {
  border-color: #ddf4e2 !important;
}

.border-green-100 {
  border-color: #d1e7dd !important;
}

.border-green-200 {
  border-color: #a3cfbb !important;
}

.border-green-300 {
  border-color: #75b798 !important;
}

.border-green-400 {
  border-color: #479f76 !important;
}

.border-green-500 {
  border-color: #198754 !important;
}

.border-green-600 {
  border-color: #146c43 !important;
}

.border-green-700 {
  border-color: #0f5132 !important;
}

.border-green-800 {
  border-color: #0a3622 !important;
}

.border-green-900 {
  border-color: #051b11 !important;
}

.border-teal-50 {
  border-color: #dcfff5 !important;
}

.border-teal-100 {
  border-color: #d2f4ea !important;
}

.border-teal-200 {
  border-color: #a6e9d5 !important;
}

.border-teal-300 {
  border-color: #79dfc1 !important;
}

.border-teal-400 {
  border-color: #4dd4ac !important;
}

.border-teal-500 {
  border-color: #20c997 !important;
}

.border-teal-600 {
  border-color: #1aa179 !important;
}

.border-teal-700 {
  border-color: #13795b !important;
}

.border-teal-800 {
  border-color: #0d503c !important;
}

.border-teal-900 {
  border-color: #06281e !important;
}

.border-black-600 {
  border-color: #1e1e1e !important;
}

.border-cyan-50 {
  border-color: #dafbff !important;
}

.border-cyan-100 {
  border-color: #cff4fc !important;
}

.border-cyan-200 {
  border-color: #9eeaf9 !important;
}

.border-cyan-300 {
  border-color: #6edff6 !important;
}

.border-cyan-400 {
  border-color: #3dd5f3 !important;
}

.border-cyan-500 {
  border-color: #0dcaf0 !important;
}

.border-cyan-600 {
  border-color: #0aa2c0 !important;
}

.border-cyan-700 {
  border-color: #087990 !important;
}

.border-cyan-800 {
  border-color: #055160 !important;
}

.border-cyan-900 {
  border-color: #032830 !important;
}

/* 2. 2. Muze Font Family CSS  */
.Montserrat-font {
  font-family: "Montserrat", sans-serif;
}

.open-sans-font {
  font-family: "Open Sans", sans-serif;
}

/* 2. 3. Muze Font Weights CSS  */
h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.btn,
.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
  font-family: "Montserrat", sans-serif;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-regular,
.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

/* 2. 4. Muze Font Sizes CSS  */
.display-1 {
  font-size: 6rem;
  font-weight: 700;
}

.display-2 {
  font-size: 5.5rem;
  line-height: 5.75rem;
  font-weight: 700;
}

.display-3 {
  font-size: 4.5rem;
  line-height: 5rem;
  font-weight: 700;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 700;
}

.display-5 {
  font-size: 2.875rem;
  font-weight: 700;
}

.display-6 {
  font-size: 2.25rem;
  font-weight: 700;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.fs-20 {
  font-size: 1.25rem;
}

h2,
.h2 {
  line-height: 3rem;
}

h3,
.h3 {
  line-height: 2.5rem;
}

h4,
.h4 {
  line-height: 2.25rem;
}

body,
big.font-size-16,
.font-size-16 {
  font-size: 1rem;
}

big,
.big {
  font-size: 1.25rem;
  line-height: 2rem;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

small,
.small,
.small {
  font-size: 0.8125rem;
}

caption,
.caption {
  font-size: 0.875rem !important;
}

.fs-11 {
  font-size: 0.6875rem !important;
}

.fs-12 {
  font-size: 0.75rem;
}

.tiny {
  font-size: 0.625rem;
}

/* 2. 5. Muze After CSS  */
.component-list:after {
  display: block;
  clear: both;
  content: "";
}

/* 2. 6. Muze Shapes CSS  */
.rounded-3 {
  border-radius: 0.1875rem !important;
}

.rounded-6 {
  border-radius: 0.375rem !important;
}

.rounded-12 {
  border-radius: 0.75rem !important;
}

.rounded-24 {
  border-radius: 1.5rem !important;
}

.rounded-29 {
  border-radius: 1.8125rem !important;
}

.rounded-40 {
  border-radius: 2.5rem !important;
}

.rounded-top {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.rounded-top-6 {
  border-radius: 0.375rem 0.375rem 0 0 !important;
}

.rounded-bottom {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.rounded-left {
  border-radius: 0.25rem 0 0 0.25rem !important;
}

.rounded-left-6 {
  border-radius: 0.375rem 0 0 0.375rem !important;
}

.rounded-right-6 {
  border-radius: 0 0.375rem 0.375rem 0 !important;
}

.rounded-right {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.rounded-pill-left {
  border-radius: 3.125rem 0 0 3.125rem !important;
}

.rounded-top-left {
  border-radius: 0.8125rem 0 0 0;
}

/* 2. 7. Muze Box Shadow CSS */
.shadow-none {
  box-shadow: none;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.04) !important;
}

.hover-shadow-primary:hover,
.hover-shadow-primary:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(13, 110, 253, 0.35);
}

.hover-shadow-success:hover,
.hover-shadow-success:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(77, 212, 172, 0.35);
}

.hover-shadow-danger:hover,
.hover-shadow-danger:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(227, 93, 106, 0.35);
}

.hover-shadow-info:hover,
.hover-shadow-info:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(13, 202, 240, 0.35);
}

.hover-shadow-secondary:hover,
.hover-shadow-secondary:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(73, 80, 87, 0.35);
}

.hover-shadow-warning:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(255, 193, 7, 0.35);
}

.hover-shadow-dark:hover,
.hover-shadow-dark:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(33, 37, 41, 0.35);
}

.hover-shadow-light:hover,
.hover-shadow-light:focus {
  box-shadow: 0 0.25rem 0.6875rem rgba(248, 252, 252, 0.35);
}

.btn-outline-secondary:hover,
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  background-color: #495057;
  border-color: #495057;
}

.btn-outline-danger:hover,
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  background-color: #e35d6a;
  border-color: #e35d6a;
}

.btn-outline-success:hover,
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  background-color: #4dd4ac;
  border-color: #4dd4ac;
}

.btn-outline-warning:hover,
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-info:hover,
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  background-color: transparent;
  border-color: #0da2f5 !important;
  color: #0da2f5 !important;
}

.btn-outline-dark:hover svg path,
.btn-outline-dark:focus svg path,
.btn-check:checked + .btn-outline-dark svg path,
.btn-check:active + .btn-outline-dark svg path,
.btn-outline-dark:active svg path,
.btn-outline-dark.active svg path,
.btn-outline-dark.dropdown-toggle.show svg path {
  fill: #0da2f5;
}

.card-hover:hover {
  box-shadow: 0 0.25rem 0.6875rem rgba(33, 37, 41, 0.15);
}

.shadow-20 {
  box-shadow: 0 0 0.125rem rgba(89, 53, 154, 0.2);
}

.shadow-40 {
  box-shadow: 0 0.125rem 0.25rem rgba(89, 53, 154, 0.2);
}

.shadow-60 {
  box-shadow: 0 0.25rem 0.4375rem rgba(89, 53, 154, 0.2);
}

.shadow-80 {
  box-shadow: 0 0.3125rem 0.625rem rgba(89, 53, 154, 0.2);
}

.shadow-100 {
  box-shadow: 0 0.375rem 0.875rem rgba(89, 53, 154, 0.2);
}

.shadow-top-20 {
  box-shadow: 0 -0.03125rem 0.125rem rgba(89, 53, 154, 0.2);
}

.shadow-top-40 {
  box-shadow: 0 -0.125rem 0.25rem rgba(89, 53, 154, 0.2);
}

.shadow-top-60 {
  box-shadow: 0 -0.25rem 0.5rem rgba(89, 53, 154, 0.2);
}

.shadow-top-80 {
  box-shadow: 0 -0.5rem 1rem rgba(89, 53, 154, 0.2);
}

.shadow-top-100 {
  box-shadow: 0 -1rem 1.5rem rgba(89, 53, 154, 0.2);
}

.shadow-dark-20 {
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-40 {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-60 {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-80 {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.04);
}

.shadow-dark-100 {
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-top-20 {
  box-shadow: 0 -0.0625rem 0.125rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-top-40 {
  box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-top-60 {
  box-shadow: 0 -0.25rem 0.5rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-top-80 {
  box-shadow: 0 -0.5rem 1rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-top-100 {
  box-shadow: 0 -1rem 1.5rem rgba(0, 0, 0, 0.4);
}

.shadow-light-top {
  box-shadow: 0 -0.375rem 0.75rem rgba(89, 53, 154, 0.2);
}

.shadow-light-bottom {
  box-shadow: 0 0.375rem 0.75rem rgba(89, 53, 154, 0.2);
}

.shadow-dark-top {
  box-shadow: 0 -0.375rem 0.75rem rgba(0, 0, 0, 0.4);
}

.shadow-dark-bottom {
  box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.4);
}

/* 2. 8. Muze Typography Page CSS */
.Fabrx-components + .Fabrx-components {
  padding-top: 6.25rem;
}

.component-item + .component-item {
  padding-top: 3.125rem;
}

.Fabrx-section {
  padding: 5rem 0;
}

/* 2.  9. Muze Avaters CSS */
.avatar {
  position: relative;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  min-width: 3.125rem;
  border-radius: 0.375rem;
}

.avatar .avatar-img {
  border-radius: 0.375rem;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatar-center {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.avatar.avatar-circle {
  border-radius: 50%;
}

.avatar.avatar-circle .avatar-img,
.avatar.avatar-circle .avatar-initials {
  border-radius: 50%;
}

.avatar.avatar-circle .avatar-status {
  bottom: 0;
  right: -0.125rem;
}

.avatar.avatar-circle .avatar-xxl-status {
  bottom: 0;
  right: 0.125rem;
}

.avatar.avatar-circle .avatar-xl-status {
  bottom: 0;
  right: 0.0625rem;
}

.avatar.avatar-circle .avatar-lg-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-circle .avatar-sm-status {
  bottom: 0;
  right: 0;
}

.avatar.avatar-xxl {
  width: 7rem;
  height: 7rem;
  min-width: 7rem;
}

.avatar.avatar-xl {
  width: 5.625rem;
  min-width: 5.625rem;
  height: 5.625rem;
}

.avatar.avatar-xl .avatar-initials {
  font-size: 2rem;
}

.avatar.avatar-lg {
  width: 4.25rem;
  min-width: 4.25rem;
  height: 4.25rem;
}

.avatar.avatar-lg .avatar-initials {
  font-size: 1.25rem;
}

.avatar.avatar-sm {
  width: 2.6875rem;
  min-width: 2.6875rem;
  height: 2.6875rem;
}

.avatar.avatar-sm .avatar-initials {
  font-size: 0.8125rem;
}

.avatar.avatar-xs {
  width: 1.75rem;
  min-width: 1.75rem;
  height: 1.75rem;
}

.avatar.avatar-xs .avatar-initials {
  font-size: 0.75rem;
}

.avatar.avatar-xss {
  width: 1.125rem;
  min-width: 1.125rem;
  height: 1.125rem;
}

.avatar-primary,
.avatar-secondary,
.avatar-success,
.avatar-danger,
.avatar-info,
.avatar-dark {
  color: #fff;
}

.avatar-warning,
.avatar-light {
  color: #000;
}

.avatar-primary {
  background-color: #0da2f5;
}

.avatar-dark-light {
  background-color: #e9ecef;
}

.avatar-secondary {
  background-color: #495057;
}

.avatar-offline {
  background-color: #adb5bd;
}

.avatar-success {
  background-color: #20c997;
}

.avatar-white {
  background-color: #fff;
}

.avatar-danger {
  background-color: #e25563;
}

.avatar-warning {
  background-color: #fd7e14;
}

.avatar-info {
  background-color: #0dcaf0;
}

.avatar-light {
  background-color: #f8fcfc;
}

.avatar-dark {
  background-color: #212529;
}

.avatar-soft-primary {
  background-color: rgba(13, 110, 253, 0.1);
  color: #0da2f5;
}

.avatar-soft-secondary {
  background-color: rgba(73, 80, 87, 0.1);
  color: #495057;
}

.avatar-soft-success {
  background-color: rgba(77, 212, 172, 0.1);
  color: #4dd4ac;
}

.avatar-soft-danger {
  background-color: rgba(227, 93, 106, 0.1);
  color: #e35d6a;
}

.avatar-soft-warning {
  background-color: rgba(255, 193, 7, 0.1);
  color: #ffc107;
}

.avatar-soft-info {
  background-color: rgba(13, 202, 240, 0.1);
  color: #0dcaf0;
}

.avatar-soft-light {
  background-color: rgba(248, 252, 252, 0.1);
  color: #f8fcfc;
}

.avatar-soft-dark {
  background-color: rgba(33, 37, 41, 0.1);
  color: #212529;
}

.avatar-initials {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  font-weight: 600;
}

.avatar-status {
  position: absolute;
  bottom: -0.525rem;
  right: -0.525rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  vertical-align: baseline;
  border: 0.125rem solid #fff;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  line-height: 1;
  font-size: 0.625rem;
  border-radius: 50%;
}

.avatar-lg-status {
  width: 1.125rem;
  min-width: 1.125rem;
  height: 1.125rem;
  font-size: 0.75rem;
}

.avatar-xl-status {
  width: 1.375rem;
  min-width: 1.375rem;
  height: 1.375rem;
  border-width: 0.1875rem;
}

.avatar-xxl-status {
  width: 1.5625rem;
  min-width: 1.5625rem;
  height: 1.5625rem;
  border-width: 0.1875rem;
}

.avatar-sm-status {
  bottom: -0.2625rem;
  right: -0.2625rem;
  width: 0.75rem;
  min-width: 0.75rem;
  height: 0.75rem;
  font-size: 0.5rem;
}

.avatar-xs-status {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  border-width: 0.0625rem;
}

.status {
  width: 0.5rem;
  min-width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: inline-block;
}

.status.online {
  background-color: #20c997;
}

.status.dnd {
  background-color: #e25563;
}

.status.away {
  background-color: #fd933a;
}

.status.offline {
  background-color: #adb5bd;
}

.avatar-group {
  display: -ms-flexbox;
  display: flex;
}

.avatar-group .avatar-xxl .avatar-img,
.avatar-group .avatar-xxl .avatar-initials {
  border: 0.3125rem solid #fff;
  font-size: 2.5rem;
  font-weight: 700;
}

.avatar-group .avatar-xl .avatar-img,
.avatar-group .avatar-xl .avatar-initials {
  border: 0.25rem solid #fff;
  font-size: 2rem;
  font-weight: 700;
}

.avatar-group .avatar-lg .avatar-img,
.avatar-group .avatar-lg .avatar-initials {
  border: 0.1875rem solid #fff;
  font-size: 1.75rem;
  font-weight: 700;
}

.avatar-group .avatar-img,
.avatar-group .avatar-initials {
  border: 0.1875rem solid #fff;
  font-size: 1rem;
  font-weight: 700;
}

.avatar-group .avatar-sm .avatar-img,
.avatar-group .avatar-sm .avatar-initials {
  border: 0.125rem solid #fff;
  font-size: 1rem;
  font-weight: 700;
}

.avatar-group .avatar-xs .avatar-img,
.avatar-group .avatar-xs .avatar-initials {
  border: 0.0625rem solid #fff;
  font-size: 0.625rem;
  font-weight: 600;
}

.avatar-group .avatar + .avatar {
  margin-left: -1.4375rem;
}

.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2.5rem;
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -2rem;
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1.25rem;
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -1rem;
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.625rem;
}

.border-transprant {
  border-color: transparent !important;
}

.avatar-border-xs {
  border: 0.0625rem solid #fff;
}

.avatar-border-sm {
  border: 0.125rem solid #fff !important;
}

.avatar-border-lg,
.avatar-border {
  border: 0.1875rem solid #fff;
}

.avatar-border-xl {
  border: 0.25rem solid #fff;
}

.avatar-border-xxl {
  border: 0.3125rem solid #fff;
}

.avatar-centered {
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

/* 2.  10. Muze Button CSS */
.btn {
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  height: 2.375rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.375rem 1.0625rem;
}

.btn[data-bs-toggle="collapse"] {
  -ms-flex-pack: start;
  justify-content: flex-start;
  height: auto;
}

.btn-check:focus + .btn,
.btn:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: none;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .btn {
  font-size: 1rem;
  height: 3rem;
  line-height: 1.875rem;
  padding: 0.5rem 1.4375rem;
}

.btn-group > .btn[type="button"] {
  padding: 0.375rem 1.84375rem;
}

.btn-group-lg > .btn[type="button"] {
  padding: 0.5rem 2.3125rem;
}

.btn-xl,
.btn-group-xl > .btn {
  height: 3.625rem;
  padding: 0.375rem 2.5rem;
  font-size: 1.25rem;
  line-height: 2.8125rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .btn {
  height: 1.9375rem;
  font-size: 0.625rem;
  line-height: 1.4375rem;
  padding: 0.25rem 0.75rem;
}

.btn-group-sm > .btn[type="button"] {
  padding: 0.25rem 1.25rem;
}

.btn-success {
  color: #fff;
}

.btn-link {
  padding: 0 !important;
  height: auto !important;
  line-height: initial !important;
  border: none !important;
  box-shadow: none !important;
}

.btn-link:focus {
  box-shadow: none !important;
}

.btn-icon {
  font-size: 1.3125rem;
  padding: 0.5rem 0.6875rem;
}

.btn-icon.btn-xl {
  font-size: 2rem;
  padding: 0.5rem 1rem;
}

.btn-icon.btn-lg,
.btn-group-lg > .btn-icon.btn {
  font-size: 1.4375rem;
  padding: 0.5rem 0.8125rem;
}

.btn-icon.btn-sm,
.btn-group-sm > .btn-icon.btn {
  font-size: 1.125rem;
  padding: 0.25rem;
  width: 1.9375rem;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}

.media .media-body {
  width: 100%;
}

/* 2.  11. Muze Badge CSS */
.badge {
  font-weight: 700;
  padding: 0.625rem 0.8125rem;
  font-size: 0.6875rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
}

.badge img {
  vertical-align: top;
}

.badge.badge-sm {
  padding: 0.34375rem 0.5rem;
}

.badge.badge-size-xl {
  width: 5.3125rem;
  height: 5.3125rem;
}

.badge-absolute {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1;
}

.btn .badge {
  padding: 0.25rem 0.3125rem;
}

.badge-primary {
  background-color: #0da2f5;
}

.badge-soft-primary {
  background-color: #e6f0ff;
  color: #0da2f5;
}

.badge-secondary {
  background-color: #495057;
}

.badge-danger {
  background-color: #e35d6a;
}

.badge-success {
  background-color: #4dd4ac;
}

.badge-cyan {
  background-color: #0aa2c0;
}

.badge-warning {
  background-color: #ffc107;
  color: #1e1e1e;
}

.badge-info {
  background-color: #0dcaf0;
}

.badge-dark {
  background-color: #1e1e1e;
}

.badge-light {
  background-color: #fff;
  color: #1e1e1e;
}

.badge-purple {
  background-color: #6f42c1;
}

/* 2.  12.  Muze Cards CSS */
.card-body-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
}

.text-white .card-title {
  color: #fff;
}

.muze-card {
  border: none;
  border-radius: 0.375rem;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.04);
  background-color: #fff;
}

.muze-card a {
  color: #212529;
}

.card-dashed {
  border: none;
}

.card-dashed .card-dashed-body {
  border: 0.0625rem dashed #495057;
  border-radius: 0.3125rem;
  transition: 0.3s;
}

/* 2.  13.  Muze Alerts CSS */
.alert {
  font-weight: 600;
  padding: 0.75rem 1.4rem;
  font-size: 1rem;
}

.alert .close {
  opacity: 1;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 0.1875rem;
  right: 0.9375rem;
  z-index: 1;
}

.alert.alert-success p {
  color: #fff;
}

.alert-dismissible .close {
  padding: 0.75rem 1rem;
}

.alert-primary {
  background-color: #0da2f5;
  border-color: #0da2f5;
  color: #fff;
}

.alert-primary .alert-link {
  color: #fff;
}

.alert-secondary {
  background-color: #dee2e6;
  border-color: #dee2e6;
  color: #1e1e1e;
}

.alert-success {
  background-color: #4dd4ac;
  border-color: #4dd4ac;
  color: #fff;
}

.alert-danger {
  background-color: #e35d6a;
  border-color: #e35d6a;
  color: #fff;
}

.alert-warning {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #1e1e1e;
}

.alert-info {
  background-color: #e6f0ff;
  border-color: #e6f0ff;
  color: #1e1e1e;
}

.alert-dark {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
}

.alert-light {
  background-color: #fff;
  border-color: #fff;
  color: #212529;
}

/* 2.  14.  Muze Checks CSS */
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0;
  margin-right: 0.5rem;
  cursor: pointer;
  border-color: #ced4da;
  border-radius: 0.25rem;
}

#no-margin-right {
  margin-right: 0;
}

.form-check-sm .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
}

.form-check-sm label {
  font-size: 0.8125rem;
}

.form-check-sm .form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-lg {
  padding-left: 2em;
}

.form-check-lg .form-check-input {
  width: 2em;
  height: 2em;
  margin-left: -2em;
}

.form-check-lg .form-check-input:checked[type="radio"] {
  background-image: url("../svg/icons/check-light.svg");
  background-size: 1rem;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check {
  margin-bottom: 0.5rem;
}

.form-check.form-check-rounded .form-check-input {
  border-radius: 50%;
}

.form-check.rounded-none .form-check-input {
  border-radius: 0;
}

.form-switch .form-check-input {
  border-radius: 2em;
  width: 2.688em;
  background-color: #dee2e6;
  border-color: #dee2e6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-color: #0da2f5;
  border-color: #0da2f5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-sm .form-check-input {
  width: 1.813em;
}

.form-switch.form-check-sm .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.5 -3.5 7 7'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-lg .form-check-input {
  width: 3.625em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-switch.form-check-lg .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3.35 -3.35 6.8 6.8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-control {
  border-color: #dee2e6;
  font-size: 0.875rem;
}

.form-control:focus {
  box-shadow: none;
}

.form-control.form-control-xl {
  height: 3.625rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-control.form-control-lg {
  font-size: 1rem;
}

.form-control.form-control-sm {
  font-size: 0.75rem;
}

textarea.form-control {
  min-height: 6.25rem;
}

textarea.form-control.form-control-xl {
  height: auto;
  min-height: 6.25rem;
}

textarea.form-control.form-control-lg {
  min-height: 6.25rem;
}

textarea.form-control.form-control-sm {
  min-height: 6.25rem;
}

.form-select {
  border-color: #dee2e6;
  font-size: 0.875rem;
  height: 2.375rem;
  background-image: url(../svg/icons/down-arrow.svg);
  background-repeat: no-repeat;
  background-size: 1rem 0.75rem;
  background-position: right 0.5rem center;
}

.form-select:disabled {
  background-color: #ced4da;
  border-color: #ced4da;
}

.form-select.form-select-lg {
  height: 3rem;
  font-size: 1rem;
  background-size: 1.125rem 0.875rem;
  padding-left: 0.75rem;
}

.form-select.form-select-xl {
  height: 3.625rem;
  font-size: 1rem;
  background-size: 1.25rem 1rem;
}

.form-select.form-select-sm {
  font-size: 0.75rem;
  height: 1.9375rem;
  background-size: 1rem 0.75rem;
}

.form-file-label {
  font-size: 0.875rem;
  font-weight: 600;
}

.form-file {
  height: 2.375rem;
}

.form-file .form-file-label {
  height: 2.375rem;
}

.form-file .form-file-label .form-file-text {
  font-size: 0.875rem;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.form-file .form-file-label .form-file-button {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}

.form-file.form-file-xl {
  height: 3.625rem;
}

.form-file.form-file-xl .form-file-input {
  height: 3.625rem;
}

.form-file.form-file-xl .form-file-label {
  height: 3.625rem;
}

.form-file.form-file-xl .form-file-label .form-file-text {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.form-file.form-file-xl .form-file-label .form-file-button {
  padding: 0.5rem 1rem;
}

.form-file.form-file-lg {
  height: 3rem;
}

.form-file.form-file-lg .form-file-label {
  height: 3rem;
}

.form-file.form-file-lg .form-file-label .form-file-text {
  font-size: 1rem;
}

.form-file.form-file-sm {
  height: 1.9375rem;
}

.form-file.form-file-sm .form-file-label {
  height: 1.9375rem;
  font-size: 0.625rem;
}

.form-file.form-file-sm .form-file-label .form-file-text {
  font-size: 0.75rem;
}

.input-group .form-control {
  height: 2.375rem;
}

.input-group .input-group-text {
  padding: 0.375rem 0.85rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.input-group.input-group-xl .input-group-text {
  padding: 0.5rem 1.125rem;
}

.input-group.input-group-xl .form-control {
  height: 3.625rem;
  font-size: 1rem;
}

.input-group.input-group-lg .form-control {
  font-size: 1rem;
  height: 3rem;
}

.input-group.input-group-lg .input-group-text {
  padding: 0.5rem 1rem;
}

.input-group.input-group-sm .form-control {
  font-size: 0.75rem;
  height: 1.9375rem;
}

.input-group.input-group-sm .input-group-text {
  padding: 0.25rem 0.95rem;
}

.form-label {
  font-size: 0.875rem;
}

.form-label.form-label-xl {
  font-size: 1rem;
}

.form-label.form-label-lg {
  font-size: 1rem;
  font-weight: 600;
}

.form-label.form-label-sm {
  font-size: 0.75rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
  padding-right: 0.9375rem;
  border-color: #e35d6a;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  background-image: none;
  padding-right: 0.9375rem;
  border-color: #20c997;
}

/* 2.  15.  Muze Navbars CSS */
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

/* 2.  16.  Muze Popovers CSS */
.popover-header {
  line-height: 1.125rem;
  font-weight: 500;
}

/* 2.  17.  Muze Transition CSS */
.transition-3d-hover {
  transition: all 0.2s ease-in-out;
}

.transition-3d-hover:hover,
.transition-3d-hover:focus {
  -webkit-transform: translateY(-0.1875rem);
  transform: translateY(-0.1875rem);
}

/* 2.  18.  Muze Multiple Select CSS */
.choices__list--multiple .choices__item {
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.choices__inner {
  min-height: 2.375rem;
  padding: 0.1875rem 0.4375rem 0;
  background-color: #fff;
}

.choices__input {
  background-color: #fff;
}

/* 2.  19.  Muze Overflow CSS */
.overflow-remove {
  overflow: inherit !important;
}

/* 2.  20.  Muze Progress Bar CSS */
.progress-circle .progressbar-text .value {
  font-weight: 600;
  font-size: 2.98598rem;
  color: #000;
}

.progress {
  background-color: #dee2e6;
}

/* 2.  21.  Muze Circle CSS */
.circle {
  border-radius: 50%;
  overflow: hidden;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}

.circle.bg-primary {
  color: #fff;
}

.circle-sm {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  font-size: 1rem;
}

.circle-md {
  width: 2.375rem;
  min-width: 2.375rem;
  height: 2.375rem;
  font-size: 1rem;
}

.circle-lg {
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  font-size: 1.5rem;
}

.circle-xl {
  width: 3.625rem;
  height: 3.625rem;
}

/* 2.  22.  Muze Tabs CSS */
/* 2.  22.  1.  Muze Tabs Gray CSS */
.nav-pills.tabs-has-garybg .nav-link {
  padding: 1.875rem 1.875rem 2.375rem;
  margin-bottom: 1.5625rem;
  background-color: #1a1919;
  color: #fff;
  border-radius: 0.75rem;
}

.nav-pills.tabs-has-garybg .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.nav-pills.tabs-has-garybg .active {
  background-color: #e35d6a;
}

.nav-pills.tabs-has-garybg .active .text-secondary {
  color: inherit !important;
}

.nav-tabs {
  border-color: #e9ecef;
}

.nav-tabs.nav-tabs-sm {
  font-size: 0.8125rem;
  font-weight: 600;
}

.nav-tabs.nav-tabs-line {
  border-width: 0.1875rem;
  border-color: #e9ecef;
}

.nav-tabs.nav-tabs-line .nav-link {
  border: 0;
  font-weight: 600;
  margin-bottom: -0.1875rem;
  border-bottom: 0.1875rem solid transparent;
}

.nav-tabs.nav-tabs-line .nav-link.active {
  border-color: #0da2f5;
  color: #1e1e1e;
  background-color: transparent;
}

.nav-tabs.nav-tabs-line .nav-link .status {
  width: 0.4375rem;
  height: 0.4375rem;
  top: -0.75rem;
}

.nav-tabs .nav-link {
  padding: 0.85rem 1.85rem;
  color: #495057;
  font-family: "Montserrat", sans-serif;
}

.nav-tabs .nav-link.active {
  border-color: #e9ecef;
}

.nav.nav-pills .nav-link {
  border-radius: 1.875rem;
  padding: 0.5rem 1.5rem;
  color: #495057;
}

.nav.nav-pills .nav-link.active {
  color: #fff;
}

.nav-verticle {
  min-width: 11.5625rem;
}

.nav-verticle .nav-link {
  color: #495057;
}

/* 2.  23.  Muze Swiper Slider CSS */
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 0.375rem;
}

.swiper-pagination {
  position: static;
  padding-top: 1.75rem;
}

.swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
  background: #343a40;
  cursor: pointer;
  opacity: 1;
  transition: all ease 0.4s;
}

.swiper-pagination-bullet-active {
  background: #0da2f5;
}

/* 2.  24.  Muze Tabler Icons CSS */
.tabler-icons li {
  padding: 1.125rem;
}

/* 2.  25.  Muze Modal Popup CSS */
.modal .btn-light {
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.modal .modal-header .btn-icon {
  height: auto;
}

.modal .modal-content {
  border-radius: 0.25rem;
  border-color: #e9ecef;
}

.modal .modal-footer {
  border-color: #e9ecef;
}

/* 2.  26.  Muze Table CSS */
.table-responsive {
  position: relative;
  z-index: 0;
}

/* 2.  27.  Muze List Group CSS */
.list-group .list-group-item {
  border-color: #dee2e6;
}

.list-group .list-group-item.active {
  border-color: #0da2f5;
}

.list-group .list-group-item.unread {
  background-color: #f8f9fa;
}

.list-group .list-group-icon {
  margin-right: 0.5rem;
}

.simplebar-track.simplebar-horizontal {
  display: none !important;
}

/* 2.  28.  Muze Btn Close CSS */
.btn-close {
  background: transparent url("../../assets/svg/icons/close1.svg");
  background-repeat: no-repeat;
}

/* 2.  29.  Muze Opacity CSS */
.opacity-100 {
  opacity: 1;
}

/* 2.  30.  Muze Position CSS */
.end-16 {
  right: 1rem;
}

.top-16 {
  top: 1rem;
}

/* 2.  31.  Muze Z-index CSS */
.zIndex-0 {
  z-index: 0;
}

/* 3. Muze Links Page CSS */
.link-underline {
  color: #6c757d;
  border-bottom: 0.0625rem dashed #6c757d;
}

.link-underline-light {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.0625rem dashed rgba(255, 255, 255, 0.7);
}

.link-underline-light:hover {
  color: white;
  border-bottom: 0.0625rem dashed white;
}

.link:hover .link-icon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.link-icon {
  position: relative;
  line-height: 1;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  vertical-align: middle;
  text-align: center;
  width: 1.375rem;
  height: 1.375rem;
  font-size: 1rem;
  color: #0da2f5;
  background-color: rgba(61, 139, 253, 0.1);
  border-radius: 50%;
  transition: 0.3s;
}

.link-collapse[aria-expanded="false"] .link-collapse-active,
.link-collapse[aria-expanded="true"] .link-collapse-default {
  display: none;
}

/* 4. Muze Dropzone Page CSS */
.dropzone {
  position: relative;
  background-color: #fff;
  border: 0;
  background-color: transparent;
  border-radius: 0.375rem;
}

.dropzone .dz-preview {
  min-height: auto;
}

.dropzone .dz-remove {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  color: #fff;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.dropzone .dz-remove * {
  cursor: pointer;
}

.dropzone .dz-message {
  position: relative;
  padding: 5rem 2rem;
  margin: 0;
  border: 0.125rem dashed #d8e2ef;
  border-radius: 0.375rem;
}

.dropzone .dz-preview-single {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

.dropzone .dz-preview-single:hover {
  z-index: auto;
}

.dropzone .dz-preview-single .dz-preview-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0 !important;
  overflow: hidden;
}

.dropzone .dz-preview-single .dz-preview-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 0.375rem;
}

.dropzone .dz-progress {
  background: #edf2f9 !important;
}

.dropzone .dz-progress .dz-upload {
  background: #2c7be5 !important;
}

.dropzone.dropzone-has-default .dz-message {
  border-color: transparent;
  transition: all 0.2s ease-in-out;
}

.dropzone.dropzone-has-default .dz-message:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dropzone.dropzone-single.dz-file-processing .dz-message {
  display: none;
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete .dz-message {
  display: block;
}

.dropzone.dropzone-single.dz-file-processing.dz-file-complete
  .dz-default-image {
  opacity: 0;
}

.dropzone.dropzone-single .dz-processing .dz-message-text {
  opacity: 0 !important;
}

.dropzone.dropzone-single .dz-processing .dz-progress {
  opacity: 1;
}

.dropzone.dropzone-single .dz-progress {
  opacity: 1;
  transition: opacity 0.4s ease-in;
  -webkit-animation: none !important;
  animation: none !important;
  width: 9.375rem !important;
  height: 0.5rem !important;
  margin: 0 !important;
  -webkit-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
  top: auto !important;
  bottom: 1.5rem;
}

.dropzone.dropzone-single .dz-complete .dz-progress {
  opacity: 0;
}

.dropzone.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
}

.dropzone.dropzone-single.dz-max-files-reached .dz-remove {
  opacity: 1;
}

.dropzone.dropzone-single.dz-max-files-reached:hover .dz-message {
  opacity: 1;
}

.dropzone.dropzone-single.dz-max-files-reached:focus .dz-message {
  opacity: 1;
}

.dropzone.dropzone-multiple {
  border: 0;
  background: transparent;
}

.dropzone.dropzone-multiple .dz-message {
  padding: 4rem 2rem;
  border: 0.125rem dashed #d8e2ef;
  border-radius: 0.375rem;
}

.dropzone.dropzone-multiple .btn * {
  cursor: pointer;
}

.dropzone.dropzone-multiple .media:first-child {
  margin-top: 1.8rem;
}

.dropzone.dropzone-multiple .dz-image {
  border-radius: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 1rem;
  border: 0.0625rem solid #d8e2ef;
}

.dropzone.dropzone-multiple .dz-progress {
  opacity: 0;
  position: relative !important;
  transition: opacity 0.4s ease-in;
  -webkit-animation: none !important;
  animation: none !important;
  width: 6.25rem !important;
  height: 0.5rem !important;
  margin-top: 0;
  margin-left: 1rem !important;
  -webkit-transform: none !important;
  transform: none !important;
  top: auto !important;
  left: auto !important;
}

.dropzone.dropzone-multiple .dz-complete .dz-progress {
  transition-delay: 1s;
  opacity: 1;
}

.dropzone.dz-drag-hover .dz-message {
  border-color: #2c7be5;
}

.dropzone-area {
  border: 0.125rem dashed #d8e2ef;
  border-radius: 0.25rem;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

.dz-preview .dropdown-toggle::after {
  display: none;
}

.dz-preview .btn-link {
  color: #6c757d;
}

@media (max-width: 767px) {
  .avatar.avatar-xxl {
    height: 5.625rem;
    min-width: 5.625rem;
    width: 5.625rem;
  }
  .avatar.avatar-xl {
    height: 4.875rem;
    min-width: 4.875rem;
    width: 4.875rem;
  }
  .h2,
  h2,
  .h2 {
    line-height: 2rem;
  }
  .btn-group-lg > .btn,
  .btn-lg,
  .btn-group-lg > .btn {
    height: 2.625rem;
    font-size: 0.9375rem;
  }
  .btn-group-xl > .btn,
  .btn-xl {
    height: 3.25rem;
    padding: 0.375rem 1.375rem;
    font-size: 1.125rem;
    line-height: 2rem;
  }
  .nav-tabs.nav-tabs-line {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 0.625rem;
    border-bottom: none;
  }
  .nav-tabs.nav-tabs-line .nav-link {
    white-space: nowrap;
    margin-bottom: 0;
    border-color: #e9ecef;
  }
}

/*
1. Muze Header Versions CSS
   1.1  Muze Default Header CSS
   1.2  Muze Top Header CSS
   1.3  Muze Double Header CSS
2. Muze Sidebar Versions CSS
   2.1  Muze Default Sidebar CSS
   2.2  Muze Sidebar Icons CSS
   2.3  Muze Sidebar Compact CSS
   2.4  Muze Customize Sidebar CSS
3. Muze Analytics Page CSS
4. Muze Project management Page CSS
5. Muze Festive Page CSS
6. Muze Settings Page CSS
7. Muze Billing Page CSS
8. Muze Sign Up Page CSS
9. Muze Sign Up Simple Page CSS
10. Muze User Profile Page CSS
11. Muze Pricing Page CSS
12. Muze Help Center Page CSS
13. Muze All Projects Page CSS
14. Muze New Projects Page CSS
15. Muze Project Details Page CSS
16. Muze Calendar Page CSS
17. Muze File Manager Page CSS
18. Muze Chat Page CSS
19. Muze Dark Mode CSS
20. Muze RTL CSS
21. Muze Container Layout CSS
*/
/* 1. Muze Header Versions CSS */
/* 1.1 Muze Default Header CSS */
.header-fixed {
  position: fixed;
  left: 16.0625rem;
  top: 0;
  right: 0;
  background-color: #f8f9fa;
  z-index: 16;
}

.header-search {
  min-width: 20rem;
}

.header-search
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin: 0;
}

.notification {
  position: relative;
}

.notification .status {
  top: -0.9375rem;
  right: 0.4375rem;
}

.header .dropdown-menu {
  margin-top: 0.875rem !important;
}

.header .customize-btn {
  display: none;
}

/* 1.2  Muze Top Header CSS */
.top-header-nav,
.double-header-nav,
.white-logo {
  display: none;
}

.top-header .top-header-nav {
  display: block;
}

.top-header .navbar-vertical.navbar-expand-lg,
.double-header .navbar-vertical.navbar-expand-lg {
  display: none;
}

.top-header .main-content,
.double-header .main-content {
  margin-left: 0;
}

.top-header .header,
.double-header .header {
  background-color: #fff;
}

.top-header .header .customize-btn,
.double-header .header .customize-btn {
  display: block;
}

.top-header .header .customize-btn svg path,
.double-header .header .customize-btn svg path {
  fill: #fff;
}

.top-header .header-fixed,
.double-header .header-fixed {
  left: 0;
}

.top-header .navbar-brand.d-lg-none,
.double-header .navbar-brand.d-lg-none {
  display: block !important;
}

.top-header .header-search,
.top-header .back-arrow,
.double-header .header-search,
.double-header .back-arrow {
  display: none;
}

.top-header .navbar,
.double-header .navbar {
  padding: 0;
  border-left: 0.0625rem solid #e9ecef;
  padding-left: 0.625rem;
  margin-left: 1.5rem;
}

.top-header .navbar .nav-item,
.double-header .navbar .nav-item {
  position: relative;
}

.top-header .navbar .nav-item .nav-link,
.double-header .navbar .nav-item .nav-link {
  font-weight: 600;
  font-size: 0.875rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.6875rem 1.375rem;
}

.top-header .navbar .nav-item .nav-link[data-bs-toggle="collapse"]:after,
.double-header .navbar .nav-item .nav-link[data-bs-toggle="collapse"]:after {
  display: block;
  content: url(../svg/icons/chevron-down1.svg);
  margin-left: 0.8125rem;
  width: 0.75rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.top-header .navbar .nav-item .nav-link[aria-expanded="true"]:after,
.double-header .navbar .nav-item .nav-link[aria-expanded="true"]:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.top-header .navbar .nav-item .nav-link svg path,
.double-header .navbar .nav-item .nav-link svg path {
  fill: #6c757d;
}

.top-header .navbar .nav-item .nav-link .docs-version,
.double-header .navbar .nav-item .nav-link .docs-version {
  border: 0.0625rem solid #6c757d;
  border-radius: 0.25rem;
  font-size: 0.6875rem;
  padding: 0 0.25rem;
  line-height: 0.875rem;
  margin-left: 0.375rem;
}

.top-header .navbar .nav-item .nav-link:hover,
.top-header .navbar .nav-item .nav-link[aria-expanded="true"],
.double-header .navbar .nav-item .nav-link:hover,
.double-header .navbar .nav-item .nav-link[aria-expanded="true"] {
  color: #1e1e1e;
}

.top-header .navbar .nav-item .nav-link:hover svg path,
.top-header .navbar .nav-item .nav-link[aria-expanded="true"] svg path,
.double-header .navbar .nav-item .nav-link:hover svg path,
.double-header .navbar .nav-item .nav-link[aria-expanded="true"] svg path {
  fill: #1e1e1e;
}

.top-header .navbar .nav-item .collapse-box,
.double-header .navbar .nav-item .collapse-box {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  min-width: 13.125rem;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 0.5625rem rgba(0, 0, 0, 0.08);
}

.top-header .navbar .nav-item .collapse-box .nav,
.double-header .navbar .nav-item .collapse-box .nav {
  padding: 0.5rem 0;
}

.top-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link,
.double-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link {
  padding: 0.5rem 1.375rem;
}

.top-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after,
.double-header .navbar .nav-item .collapse-box .nav .nav-item .nav-link:after {
  margin-left: auto;
}

.top-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box,
.double-header .navbar .nav-item .collapse-box .nav .nav-item .collapse-box {
  position: static;
  padding: 0 0 0 0.625rem;
  box-shadow: none;
}

.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link,
.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link {
  position: relative;
  font-weight: normal;
  padding: 0.3125rem 1.375rem;
}

.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link:before,
.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link:before {
  content: "";
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background-color: #6c757d;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.4375rem;
}

.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link.active,
.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link.active {
  font-weight: 600;
}

.top-header footer .navbar,
.double-header footer .navbar {
  border: none;
  padding-left: 0;
  margin-left: 0;
}

/* 1.3  Muze Double Header CSS */
.double-header .navbar-brand {
  padding: 0;
}

.double-header .navbar-brand img {
  display: none;
}

.double-header .navbar-brand .white-logo {
  display: inline-block;
}

.double-header .double-header-nav {
  display: block;
  padding: 0.6875rem 0.9375rem;
}

.double-header .header-body {
  background-color: #1a1919;
}

.double-header .header-body .customize-btn {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  color: #1e1e1e;
}

.double-header .header-body .customize-btn svg path {
  fill: #1e1e1e;
}

.double-header .header-body .customize-btn:hover {
  background-color: #ffcd39 !important;
  border-color: #ffcd39 !important;
}

.double-header .grid-option > a svg path {
  fill: #fff;
}

.double-header .navbar {
  border-left: none;
  margin-left: 0;
}

.double-header .main-content {
  margin-top: 10.125rem;
}

.double-header .chat-items {
  height: calc(100vh - 27.25rem);
}

.double-header .chat-list {
  height: calc(100vh - 16.3rem);
}

/* 2. Muze Sidebar Versions CSS */
/* 2.1  Muze Default Sidebar CSS */
.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-vertical.navbar-expand-lg {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 100%;
  height: 100%;
  max-width: 16.0625rem;
  padding-left: 2rem;
  border-right: 0.0625rem solid #e9ecef;
  padding-right: 2rem;
}

.navbar-vertical.navbar-expand-lg .navbar-collapse {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: auto;
  min-width: 100%;
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: auto;
}

.navbar-vertical.navbar-expand-lg .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 15rem);
  margin-left: -2rem;
  margin-right: -2rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
  padding: 0;
  margin-top: 0.625rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle small,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-item.nav-subtitle .small {
  font-family: "Montserrat", sans-serif;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  color: #adb5bd;
  display: block;
  padding: 0 2rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.775rem 2rem;
  font-weight: 600;
  color: #6c757d;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link svg path {
  fill: #6c757d;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link[aria-expanded="true"] {
  color: #1e1e1e;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover svg path,
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active svg path,
.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[aria-expanded="true"]
  svg
  path {
  fill: #1e1e1e;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .docs-version {
  border-radius: 0.25rem;
  font-size: 0.6875rem;
  font-weight: 600;
  padding: 0 0.5rem;
  border: 0.0625rem solid #6c757d;
  color: #6c757d;
  margin-left: 0.5rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item {
  margin-top: 0;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
  padding-left: 3.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
  padding-left: 4.8rem;
  font-weight: 500;
}

.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav
  .nav-link:hover:before {
  background-color: #1e1e1e;
}

.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav
  .nav-link.active {
  font-weight: 600;
}

.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav
  .nav-link:before {
  content: "";
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background-color: #6c757d;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.4375rem;
}

.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"]:after {
  display: block;
  content: url("../svg/icons/chevron-down1.svg");
  margin-left: auto;
  width: 0.75rem;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.navbar-vertical .navbar-vertical-footer-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 0;
}

.navbar-vertical .navbar-vertical-footer-list > li {
  list-style: none;
  padding: 0.25rem 0;
}

.navbar-vertical .navbar-vertical-footer-list > li .dropdown-menu {
  min-width: 8.875rem;
}

.navbar-vertical .navbar-vertical-footer-list > li a:hover svg path {
  fill: #1e1e1e;
}

.navbar-vertical .navbar-vertical-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.625rem 2.125rem;
  background-color: #fff;
}

.nav-sm .nav-link {
  font-size: 0.8125rem;
}

.navbar-light {
  background-color: #fff;
}

/* 2.2 Muze Sidebar Icons CSS */
.sidebar-icons .header-fixed,
.sidebar-compact .header-fixed {
  left: 5rem;
}

.sidebar-icons .navbar-vertical.navbar-expand-lg,
.sidebar-compact .navbar-vertical.navbar-expand-lg {
  max-width: 5rem;
  padding: 0.5rem 0;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  overflow: visible;
  text-align: center;
  z-index: 2;
}

.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-collapse,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-collapse {
  padding: 0;
  margin: 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li:hover
  > .collapse-box,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li:hover
  > .collapse-box {
  display: block !important;
  height: auto !important;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li:hover
  > .collapse-box
  > .collapse-box:not(.show),
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li:hover
  > .collapse-box
  > .collapse-box:not(.show) {
  display: none;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li
  > .collapse-box,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .simplebar-content
  > li
  > .collapse-box {
  display: none;
}

.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav {
  margin: 0;
  height: calc(100vh - 10rem);
}

.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
  position: static;
  margin-top: 1.875rem;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link {
  font-size: 0;
  position: relative;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  .ms-2,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  .ms-2 {
  margin-left: 0 !important;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle {
  margin-top: 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle
  small,
.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle
  .small,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle
  small,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item.nav-subtitle
  .small {
  font-size: 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  position: fixed;
  top: inherit;
  left: 5rem;
  min-width: 9.375rem;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0.0625rem 0.5625rem rgba(0, 0, 0, 0.08);
  z-index: 99;
  padding: 0.5rem 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  > .nav,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  > .nav {
  min-width: 13.125rem;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-item,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-item {
  margin-top: 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-link,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-link {
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0.5rem 0.9375rem;
  font-size: 0.875rem;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-link[data-bs-toggle="collapse"]:after,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .nav-link[data-bs-toggle="collapse"]:after {
  display: block;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box {
  position: static;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box
  .nav-link,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box
  .nav-link {
  padding-left: 1.5625rem;
}

.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link,
.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0.625rem;
  -ms-flex-pack: center;
  justify-content: center;
}

.sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link .status,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  .status {
  top: -1rem;
  left: 0.375rem;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  > span.docs-version,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  > span.docs-version {
  position: absolute;
  top: 100%;
  left: 0;
  width: 2.375rem;
  right: 0;
  margin: 0 auto;
}

.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"]:after,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"]:after {
  display: none;
}

.sidebar-icons .navbar-vertical .navbar-vertical-footer,
.sidebar-compact .navbar-vertical .navbar-vertical-footer {
  display: none;
}

.sidebar-icons .signout,
.sidebar-compact .signout {
  display: inline-block !important;
}

.sidebar-icons .signout img,
.sidebar-compact .signout img {
  max-width: 1.25rem;
}

.sidebar-icons .signout .btn-lg,
.sidebar-icons .signout .btn-group-lg > .btn,
.sidebar-compact .signout .btn-lg,
.sidebar-compact .signout .btn-group-lg > .btn {
  padding: 0.5rem 0.8125rem;
}

.sidebar-icons .signout .btn-block,
.sidebar-compact .signout .btn-block {
  width: auto;
}

.sidebar-icons .signout img,
.sidebar-compact .signout img {
  display: block;
}

.sidebar-icons .signout span,
.sidebar-compact .signout span {
  display: none;
}

.sidebar-icons .main-content,
.sidebar-compact .main-content {
  margin-left: 5rem;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.sidebar-icons .navbar-brand img,
.sidebar-compact .navbar-brand img {
  display: none;
}

.sidebar-icons .navbar-brand .muze-icon,
.sidebar-compact .navbar-brand .muze-icon {
  display: block;
  margin: 0 auto;
}

.sidebar-icons .back-arrow svg,
.sidebar-icons .back-arrow img,
.sidebar-compact .back-arrow svg,
.sidebar-compact .back-arrow img {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

/* 2.3 Muze Sidebar Compact CSS */
.sidebar-compact .header-fixed {
  left: 9.0625rem;
}

.sidebar-compact .navbar-brand img {
  display: none;
}

.sidebar-compact .navbar-brand .muze-icon {
  display: inline-block;
}

.sidebar-compact .navbar-vertical.navbar-expand-lg {
  max-width: 9.0625rem;
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
  margin-top: 1.375rem;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link {
  font-size: 0;
  display: block;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  span:not(.docs-version) {
  font-size: 0.875rem;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  img,
.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  svg {
  display: block;
  margin: 0 auto 0.5rem;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  .status {
  left: auto;
  top: -0.375rem;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  left: 9.0625rem;
  text-align: left;
}

.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  .nav
  .nav-item
  > .nav-link {
  display: -ms-flexbox;
  display: flex;
}

.sidebar-compact .main-content {
  margin-left: 9.0625rem;
}

.sidebar-compact .signout .btn-lg,
.sidebar-compact .signout .btn-group-lg > .btn {
  padding: 0.625rem 1.125rem;
  height: 3.625rem;
}

.sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav {
  height: calc(100vh - 10.9375rem);
}

/* 2.4  Muze Customize Sidebar CSS */
.customize-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
  background-color: #f8f9fa;
  width: 29.6875rem;
  height: 100vh;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  transition: all ease 0.4s;
}

.customize-sidebar .btn-light:hover {
  background-color: #cfe2ff !important;
  border-color: #cfe2ff !important;
}

.customize-sidebar .btn-light:hover svg path {
  fill: #0da2f5;
}

.customize-body {
  height: calc(100vh - 16.5625rem);
}

.customizer-controls .pe-4 {
  padding-right: 0 !important;
}

.customize-box {
  overflow: hidden;
}

.customize-box:before {
  content: "";
  background-color: rgba(26, 25, 25, 0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 17;
}

.customize-box .customize-sidebar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.sidebar-style .form-check {
  position: relative;
  padding-left: 0;
}

.sidebar-style .form-check-input {
  position: absolute;
  bottom: 3px;
  z-index: 0;
  left: 1.5em;
}

.sidebar-style
  .form-check-input:checked
  ~ .form-check-label
  img.border-gray-400 {
  border-color: #0da2f5 !important;
}

.sidebar-style .form-check-label img {
  max-width: 6.25rem;
}

.sidebar-style .form-check-label span {
  padding-left: 1.5em;
}

/* 3. Muze Analytics Page CSS */
.dark-radio .form-check-input {
  background-color: #343a40;
  border-color: #6c757d;
}

.dark-radio .form-check-input:checked {
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.link-dark:hover {
  color: #0da2f5;
}

.link-dark:hover svg path {
  fill: #0da2f5;
}

.signout img {
  max-width: 0.875rem;
}

.main-content {
  margin-left: 16.0625rem;
  margin-top: 6.0625rem;
}

.small-title {
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #adb5bd;
  font-weight: 600;
  margin-bottom: 0.125rem;
}

.modal-close {
  margin-right: -0.625rem;
}

.form-select-sm {
  font-weight: 600;
  padding-right: 1.375rem;
  background-position: right 0.3rem center;
}

.card {
  border: none;
}

.card-header {
  border-color: rgba(108, 117, 125, 0.125);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  height: 3.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #fff;
}

.list-group .list-group-item {
  padding: 0.75rem 0.125rem;
  border-color: #e9ecef;
}

.list-group .list-group-item h6 a,
.list-group .list-group-item .h6 a {
  color: #495057;
}

.list-group .list-group-item h6 a:hover,
.list-group .list-group-item .h6 a:hover {
  color: #0da2f5;
}

.dropdown-ellipses {
  color: #adb5bd;
}

.dropdown-ellipses:hover {
  color: #0da2f5;
}

.dropdown-ellipses:after {
  display: none;
}

.dropdown-ellipses .fas {
  font-size: 0.8125rem;
}

.dropdown-menu {
  border: none;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.16);
  border-radius: 0.75rem;
  padding: 0.5rem 0;
  color: #6c757d;
  min-width: 12.1875rem;
  z-index: 6;
  margin-top: 0.3125rem !important;
  width: 100%;
}

.dropdown-menu .dropdown-body {
  max-height: 23.4375rem;
  overflow-y: auto;
}

.dropdown-menu .dropdown-item {
  font-size: 0.8125rem;
  font-weight: 600;
  padding: 0.625rem 1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-menu .dropdown-item:not(.active):not(:active) {
  color: #495057;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  background-color: #e9ecef;
  color: #1e1e1e !important;
}

.dropdown-menu .dropdown-item:hover svg path,
.dropdown-menu .dropdown-item:active svg path,
.dropdown-menu .dropdown-item.active svg path {
  fill: #1e1e1e;
}

.dropdown-menu .dropdown-item.unread {
  background-color: #f8f9fa;
}

.dropdown-menu .dropdown-item.unread .dropdown-title {
  color: #1e1e1e;
}

.dropdown-menu .dropdown-item.unread .dropdown-content {
  color: #6c757d;
  font-weight: 600;
}

.dropdown-menu .dropdown-item .dropdown-content {
  font-weight: normal;
}

.dropdown-menu .dropdown-divider {
  border-color: #dee2e6;
  opacity: 1;
}

.dropdown-menu .dropdown-sub-title {
  font-size: 0.6875rem;
  padding: 0.5rem 1rem 0.3125rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #adb5bd;
}

.dropdown-menu .nav-tabs-line .nav-item {
  width: 50%;
  text-align: center;
}

.profile-dropdown > .dropdown-menu {
  min-width: 15.5625rem;
}

.profile-dropdown > .dropdown-menu .dropdown-menu {
  min-width: 10rem;
  right: 100%;
  margin-right: 0.375rem;
  top: 0;
}

.profile-dropdown .dropdown-item {
  padding: 0.75rem 1.5rem;
}

.grid-option > .dropdown-menu {
  min-width: 23.625rem;
}

.grid-option .dropdown-body .dropdown-item {
  border-top: 0.0625rem solid #e9ecef;
  padding: 1rem 1.5rem;
}

.export-dropdown .dropdown-menu .dropdown-sub-title {
  color: #495057;
  padding: 0.5rem 1.5rem;
}

.export-dropdown .dropdown-menu .dropdown-item {
  padding: 0.625rem 1.5rem;
}

.export-dropdown .btn svg {
  transition: all ease 0.4s;
}

.export-dropdown .btn[aria-expanded="true"] svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.get-startedtwo {
  position: absolute;
  right: 1.875rem;
  bottom: -1.5625rem;
  z-index: -1;
}

.card-table {
  margin-bottom: 0;
  color: #495057;
}

.card-table td {
  font-size: 1rem;
  padding: 0.9375rem 2rem;
  vertical-align: middle;
  border-color: #e9ecef !important;
  color: #1e1e1e;
}

.card-table th {
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: normal;
  color: #6c757d;
  padding: 0.8125rem 2rem;
  background-color: #f8f9fa;
  border-color: #f8f9fa !important;
}

.card-table th:last-child {
  width: 6.875rem;
}

.card-table tr:last-child td {
  border-bottom: 0;
}

.card-table.table-nowrap td,
.card-table.table-nowrap th,
.card-table.table [data-sort] {
  white-space: nowrap;
}

.check-list .form-check-input:checked ~ .form-check-label {
  text-decoration: line-through;
  color: #adb5bd;
}

.check-list .form-check-label {
  color: #495057;
}

.list-group-activity .list-group-item {
  border-bottom: none;
  position: relative;
  z-index: 1;
}

.list-group-activity .list-group-item:first-child .col-auto:after {
  top: 1.875rem;
}

.list-group-activity .list-group-item:last-child .col-auto:after {
  height: 50%;
}

.list-group-activity .list-group-item .col-auto:after {
  content: "";
  display: block;
  height: 100%;
  width: 0.0625rem;
  background-color: #ced4da;
  position: absolute;
  left: 1.4375rem;
  top: 0;
  z-index: -1;
}

.badge-sm {
  padding: 0.3125rem 0.625rem;
  font-size: 0.6em;
}

.form-check-sm .form-check-input {
  margin-top: 0.25em;
}

.form-check-sm .form-check-label {
  font-size: 90%;
}

.checklist-box textarea.form-control {
  resize: none;
  overflow: hidden;
  overflow-wrap: break-word;
  height: 1.5625rem;
  min-height: auto;
  padding-left: 0;
  border: none;
  font-size: 1rem;
  color: #495057;
}

.checklist-box textarea.form-control::-webkit-input-placeholder {
  color: #495057;
}

.checklist-box textarea.form-control::-moz-placeholder {
  color: #495057;
}

.checklist-box textarea.form-control:-ms-input-placeholder {
  color: #495057;
}

.checklist-box textarea.form-control:-moz-placeholder {
  color: #495057;
}

.checklist-box textarea.form-control:focus {
  box-shadow: none;
}

.muze-icon {
  display: none;
}

.nav-tabs-sm .nav-link {
  font-size: 0.8125rem;
}

.card-header-tabs {
  border-bottom: none;
  margin: -0.75rem 0;
}

.card-header-tabs .nav-item {
  padding: 0 0.5rem;
}

.card-header-tabs .nav-link {
  border: none;
  padding: 1.25rem 0;
  color: #adb5bd;
  font-weight: 500;
}

.card-header-tabs .nav-link.active {
  border-bottom: 0.0625rem solid #0da2f5;
  color: #000;
}

.btn-link.link-primary:hover {
  color: #212529;
}

.btn-link.link-primary:hover svg path {
  fill: #212529;
}

.get-started-img {
  position: absolute;
  bottom: -19.375rem;
  right: 3.125rem;
  z-index: -1;
}

.get-started-two {
  bottom: -1.875rem;
}

footer .navbar {
  -ms-flex-pack: end;
  justify-content: flex-end;
  border: none;
}

footer .navbar li {
  padding: 0.3125rem 0 0.3125rem 3.125rem;
}

footer .navbar li a {
  color: #6c757d;
  font-size: 1rem;
}

footer .navbar li a:hover {
  color: #0da2f5;
}

/* 4. Muze Project management Page CSS */
.get-startedthree {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -18.4375rem;
}

.project-management-template table td .progress {
  width: 10.9375rem;
  margin-left: 0.375rem;
}

/* 5. Muze Festive Page CSS */
.title-box {
  min-height: 3.125rem;
}

/* 6. Muze Settings Page CSS */
.sidebar-nav {
  padding-left: 0;
  margin-bottom: 0.5rem;
}

.sidebar-nav li {
  list-style: none;
  border-top: 0.0625rem solid #e9ecef;
}

.sidebar-nav li a {
  font-weight: 600;
  color: #adb5bd;
  padding: 0.8125rem 1.25rem;
  display: block;
  font-size: 0.875rem;
  border-left: 0.25rem solid transparent;
}

.sidebar-nav li a:hover,
.sidebar-nav li a.active {
  color: #1e1e1e;
  border-left-color: #0da2f5;
  background-color: #f8f9fa;
}

.sidebar-nav li a:hover svg path,
.sidebar-nav li a.active svg path {
  fill: #1e1e1e;
}

.sidebar-nav li a svg path {
  fill: #adb5bd;
  transition: all ease 0.4s;
}

.form-style-two .form-control {
  color: #6c757d;
}

.form-style-two ul {
  padding-left: 0;
}

.form-style-two ul li {
  font-size: 0.8125rem;
  color: #343a40;
  list-style: none;
  margin-bottom: 0.25rem;
}

/* 7. Muze Billing Page CSS */
.invoice-table tr {
  vertical-align: middle;
}

.invoice-table tr:last-child td {
  border: none;
}

.invoice-table td {
  padding: 1.5625rem 0.625rem;
  white-space: nowrap;
}

.invoice-table td:first-child {
  padding-left: 0;
}

.invoice-table td:last-child {
  padding-right: 0;
}

.invoice-template .card-table td {
  padding: 1.375rem 2rem;
}

.invoice-template .card-table td:last-child {
  text-align: right;
}

/* 8. Muze Sign Up Page CSS */
.signup-template .cover-fit {
  height: 100vh;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.login-form {
  max-width: 32.9375rem;
}

/* 9. Muze Sign Up Simple Page CSS */
.login-form a:hover,
.login-form a:focus,
.simple-login-form a:hover,
.simple-login-form a:focus,
.signup-simple-template a:hover,
.signup-simple-template a:focus {
  color: #0da2f5 !important;
}

.login-form .form-check-label,
.simple-login-form .form-check-label,
.signup-simple-template .form-check-label {
  margin-top: 0.125rem;
}

.signup-header {
  padding: 4rem 1.875rem 16.625rem;
}

.simple-login-form {
  width: 30.75rem;
  margin: -15rem auto 5.4375rem;
  padding: 3.5rem;
}

/* 10. Muze User Profile Page CSS */
.user-profile-template a:not(.btn):hover,
.user-profile-template a:not(.btn):focus {
  color: #0da2f5;
}

.user-profile-template .avatar-xs {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
}

.user-profile-template .list-group .list-group-item {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.user-profile-template .tiny {
  font-size: 0.625rem !important;
}

.user-profile-template .get-startedtwo {
  bottom: -6.25rem;
  right: -8.4375rem;
}

.profile-list {
  margin-top: 1rem;
  margin-bottom: 0;
}

.profile-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.5rem 0;
}

.profile-list li span + span {
  width: calc(100% - 2rem);
  padding-left: 0.75rem;
  color: #495057;
}

.group-cards .avatar.avatar-lg {
  width: 3.5rem;
  min-width: 3.5rem;
  height: 3.5rem;
}

.group-cards .title-box {
  min-height: 5.625rem;
}

/* 11. Muze Pricing Page CSS */
.pricing-template .top-less {
  margin-top: -15.625rem;
}

.pricing-template .badge.position-absolute.end-0 {
  right: 1rem !important;
}

.pricing-template .badge.position-absolute.top-0 {
  top: 1rem !important;
}

.pricing-template .nav-segment {
  padding: 0.375rem;
}

.pricing-template .nav-segment.nav-pills .nav-link {
  padding: 0.4rem 1.2rem;
  color: #fff;
  border-radius: 1.25rem;
}

.pricing-template .nav-segment.nav-pills .nav-link.active {
  background-color: #ffc107;
  color: #1e1e1e;
}

.pricing-template .nav-segment.nav-pills .show > .nav-link {
  background-color: #ffc107;
  color: #1e1e1e;
}

.muze-collapes .card {
  background-color: transparent;
  border: none;
  border-bottom: 0.0625rem solid #e9ecef !important;
  padding: 0.5rem 0;
  border-radius: 0;
}

.muze-collapes .card .card-header {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
  height: auto;
}

.muze-collapes .card .card-header h5,
.muze-collapes .card .card-header .h5 {
  width: 100%;
}

.muze-collapes .card .card-header .btn-light {
  background-color: transparent !important;
  border: none !important;
  padding: 0.75rem 2rem 0.75rem 0 !important;
  font-size: 1.25rem;
  font-weight: 600;
  box-shadow: none;
  position: relative;
}

.muze-collapes .card .card-header .btn-light[aria-expanded="true"]:before {
  display: none;
}

.muze-collapes .card .card-header .btn-light:after {
  content: "";
  height: 0.125rem;
  width: 1.25rem;
  background-color: #1e1e1e;
  border-radius: 0.625rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.muze-collapes .card .card-header .btn-light:before {
  content: "";
  height: 1.25rem;
  width: 0.125rem;
  background-color: #1e1e1e;
  border-radius: 0.625rem;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0.5625rem;
}

.plan-list li {
  line-height: 1.75rem;
  color: #495057;
}

.plan-list li + li {
  margin-top: 0.9375rem;
}

.plan-list li span {
  padding-left: 0.8125rem;
}

/* 12. Muze Help Center Page CSS */
.search-less {
  margin-top: -2.5rem;
}

.search-left {
  width: 100%;
}

/* 13. Muze All Projects Page CSS */
.all-projects-template a:not(.btn):hover,
.all-projects-template a:not(.btn):focus {
  color: #0da2f5;
}

.all-projects-template .nav-pills .nav-link {
  border-radius: 1.875rem;
  font-size: 0.9375rem;
  color: #495057;
}

.all-projects-template .nav-pills .nav-link.active {
  font-weight: 600;
  color: #fff;
  background-color: #0da2f5;
}

/* 14. Muze New Projects Page CSS */
.new-projects-template .form-label {
  color: #1e1e1e;
}

.new-projects-template form .circle-xl {
  width: 6.25rem;
  height: 6.25rem;
}

.ql-container.ql-snow {
  border-color: #e9ecef;
  border-radius: 0 0 0.25rem 0.25rem;
  height: 18.125rem;
  font-size: 1rem;
  color: #1e1e1e;
  font-style: normal;
  font-family: inherit;
}

.ql-toolbar.ql-snow {
  border-color: #e9ecef;
  border-radius: 0.25rem 0.25rem 0 0;
}

.ql-editor.ql-blank:before {
  font-style: normal;
}

.step-list {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
}

.step-list .circle {
  margin: 0 auto;
}

.step-list li {
  position: relative;
  list-style: none;
  width: 33.33%;
  text-align: center;
}

.step-list li:not(:first-child):before {
  content: "";
  height: 0.125rem;
  width: 100%;
  background-color: #e9ecef;
  position: absolute;
  left: -50%;
  top: 1.5625rem;
  z-index: -1;
}

.step-list li.active:before {
  background-color: #0da2f5;
}

/* 15. Muze Project Details Page CSS */
.activity-list .list-group-item {
  border: none;
  padding-top: 0;
  padding-bottom: 2.5rem;
  position: relative;
}

.activity-list .list-group-item:not(:last-child):before {
  content: "";
  width: 0.0625rem;
  height: 100%;
  background-color: #e9ecef;
  position: absolute;
  z-index: 0;
  top: 2.8125rem;
  left: 4.0625rem;
}

/* 16. Muze Calendar Page CSS */
.fc .fc-view-harness {
  z-index: 0;
}

.calendar-template .calendar-box a {
  color: #343a40;
  font-weight: normal;
}

.calendar-template .fc .fc-button-primary {
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.calendar-template .fc .fc-button-primary:not(:disabled).fc-button-active,
.calendar-template .fc .fc-button-primary:not(:disabled):active,
.calendar-template .fc .fc-button-primary:not(:disabled):hover {
  background-color: #0da2f5;
  border-color: #0da2f5;
}

.calendar-template .fc .fc-today-button {
  background-color: #9ec5fe;
  border-color: #9ec5fe;
}

.calendar-template .fc .fc-toolbar-title {
  font-weight: 600;
}

/* 17. Muze File Manager Page CSS */
.file-manager-template .dropdown.top-0 {
  top: 0.5rem !important;
}

.file-manager-template .dropdown.end-0 {
  right: 0.5rem !important;
}

.file-manager-template .btn-icon.btn-sm,
.file-manager-template .btn-group-sm > .btn-icon.btn {
  width: 1.5rem;
  height: 1.5rem;
}

/* 18. Muze Chat Page CSS */
.chat-header a.circle {
  background-color: #e9ecef;
}

.chat-header a.circle:hover {
  background-color: #0da2f5;
}

.chat-header a.circle:hover svg path {
  fill: #fff;
}

.opacity-1 {
  opacity: 1;
}

.chat-list {
  height: calc(100vh - 12.125rem);
}

.chat-list a {
  padding: 1.625rem 2.5rem;
  display: block;
  color: #1e1e1e;
  border-left: 0.3125rem solid transparent;
}

.chat-list a.active {
  border-color: #0da2f5;
  background-color: #e6f0ff;
}

.chat-list a.active h6,
.chat-list a.active .h6 {
  font-weight: 600;
}

.chat-list a.unread {
  background-color: #f8f9fa;
}

.chat-list a.unread h6,
.chat-list a.unread .h6,
.chat-list a.unread p {
  font-weight: 600;
  color: #1e1e1e;
}

.chat-list a.unread .unread-status {
  display: block;
}

.chat-list a .unread-status {
  display: none;
  position: absolute;
  right: 0;
  bottom: 0.625rem;
  z-index: 0;
}

.chat-list a h6,
.chat-list a .h6 {
  font-weight: normal;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0.25rem;
}

.chat-list a p {
  font-size: 0.8125rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #6c757d;
  margin-bottom: 0;
  padding-right: 3.4375rem;
}

.chat-list .media .media-body {
  width: calc(100% - 4.125rem);
  position: relative;
}

.chat-item {
  padding: 1.5rem 0;
  max-width: 48.125rem;
}

.chat-item-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.chat-message {
  padding-left: 1.5625rem;
  padding-top: 0.625rem;
}

.message-box {
  background-color: #f8f9fa;
  padding: 1.125rem 2rem;
  border-radius: 0.5rem;
  position: relative;
}

.message-box:before {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 1.125rem solid transparent;
  border-right: 1.25rem solid #f8f9fa;
  position: absolute;
  bottom: 30%;
  left: -1.125rem;
}

.message-box p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 2;
  color: #343a40;
}

.message-files {
  padding-left: 2.1875rem;
}

.message-files ul {
  padding-left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0.625rem -0.375rem;
}

.message-files ul li {
  list-style: none;
  padding: 0 0.375rem;
  width: 18%;
}

.message-sender {
  padding-left: 2.5rem;
}

.message-sender .message-box {
  background-color: #0da2f5;
}

.message-sender .message-box:before {
  border-right: 1.25rem solid #0da2f5;
}

.message-sender .message-box p {
  color: #fff;
}

.message-sender .message-files {
  padding-left: 4.6875rem;
}

.attachment {
  background-color: #e9ecef;
  border-radius: 1.5rem;
  padding: 0.75rem 1.5rem;
  margin: 0.375rem 0.375rem 0.375rem 0;
  display: inline-block;
  color: #343a40;
}

.chat-items {
  height: calc(100vh - 23.25rem);
}

.message-footer .form-control {
  font-size: 1rem;
  border: none;
  resize: none;
  padding-left: 0;
  min-height: 3.125rem;
}

.message-footer a:not(.btn) {
  margin-right: 2.5rem;
}

.message-footer a:not(.btn):hover svg path {
  fill: #0da2f5;
}

/* 19. Muze Dark Mode CSS */
.white-logo2,
.muze-icon-white {
  display: none;
}

.muze-dark-mode {
  color: #adb5bd;
}

.muze-dark-mode.bg-gray-100 {
  background-color: #1e1e1e !important;
}

.muze-dark-mode .apexcharts-gridline {
  stroke: #343a40;
}

.muze-dark-mode .highcharts-yaxis-grid .highcharts-grid-line {
  stroke: #343a40;
}

.muze-dark-mode .simplebar-scrollbar:before {
  background-color: #ced4da;
}

.muze-dark-mode .simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.muze-dark-mode .form-control {
  color: #e9ecef;
}

.muze-dark-mode .progress {
  background-color: #343a40;
}

.muze-dark-mode .navbar-vertical-footer-list > li:not(.dropup) a img {
  -webkit-filter: brightness(6);
  filter: brightness(6);
}

.muze-dark-mode.sidebar-icons .navbar-vertical .navbar-brand img,
.muze-dark-mode.sidebar-compact .navbar-vertical .navbar-brand img {
  display: none;
}

.muze-dark-mode.sidebar-icons .navbar-vertical .navbar-brand img.white-logo2,
.muze-dark-mode.sidebar-compact .navbar-vertical .navbar-brand img.white-logo2 {
  display: none;
}

.muze-dark-mode.sidebar-icons
  .navbar-vertical
  .navbar-brand
  img.muze-icon-white,
.muze-dark-mode.sidebar-compact
  .navbar-vertical
  .navbar-brand
  img.muze-icon-white {
  display: block;
  margin: 0 auto;
}

.muze-dark-mode.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box,
.muze-dark-mode.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  background-color: #121111;
}

.muze-dark-mode .navbar-vertical .navbar-brand img {
  display: none;
}

.muze-dark-mode .navbar-vertical .navbar-brand img.white-logo2 {
  display: block;
}

.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  .docs-version {
  color: #adb5bd;
  border-color: #adb5bd;
}

.muze-dark-mode.top-header .header .navbar-brand img,
.muze-dark-mode.double-header .header .navbar-brand img {
  display: none;
}

.muze-dark-mode.top-header .header .navbar-brand img.white-logo,
.muze-dark-mode.double-header .header .navbar-brand img.white-logo {
  display: block;
}

.muze-dark-mode.top-header .header .customize-btn.btn-dark,
.muze-dark-mode.double-header .header .customize-btn.btn-dark {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  color: #1e1e1e;
}

.muze-dark-mode.top-header .header .customize-btn.btn-dark svg path,
.muze-dark-mode.double-header .header .customize-btn.btn-dark svg path {
  fill: #1e1e1e;
}

.muze-dark-mode.top-header .header .customize-btn.btn-dark:hover,
.muze-dark-mode.double-header .header .customize-btn.btn-dark:hover {
  background-color: #ffcd39 !important;
  border-color: #ffcd39 !important;
}

.muze-dark-mode.top-header .navbar,
.muze-dark-mode.double-header .navbar {
  border-color: #343a40;
}

.muze-dark-mode.top-header .navbar .nav-item .nav-link,
.muze-dark-mode.double-header .navbar .nav-item .nav-link {
  color: #e9ecef;
}

.muze-dark-mode.top-header .navbar .nav-item .nav-link svg path,
.muze-dark-mode.double-header .navbar .nav-item .nav-link svg path {
  fill: #adb5bd;
}

.muze-dark-mode.top-header .navbar .nav-item .collapse-box,
.muze-dark-mode.double-header .navbar .nav-item .collapse-box {
  background-color: #121111;
}

.muze-dark-mode .muze-hamburger img {
  -webkit-filter: brightness(10);
  filter: brightness(10);
}

.muze-dark-mode .avatar .avatar-initials {
  color: #1e1e1e;
}

.muze-dark-mode .btn-dark-100:not(:hover) {
  background-color: #343a40;
  color: #adb5bd;
}

.muze-dark-mode .btn-dark-100:not(:hover) svg path {
  fill: #adb5bd;
}

.muze-dark-mode .btn-dark {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.muze-dark-mode .btn-dark:hover {
  background-color: #121111 !important;
  border-color: #121111 !important;
}

.muze-dark-mode .link-dark:not(:hover) {
  color: #adb5bd;
}

.muze-dark-mode .link-dark:not(:hover) svg path {
  fill: #adb5bd;
}

.muze-dark-mode .list-group .text-gray-700 {
  color: #e9ecef !important;
}

.muze-dark-mode .list-group .list-group-item {
  background-color: #1a1919;
  border-color: #343a40;
}

.muze-dark-mode .list-group .list-group-item h6 a:not(:hover),
.muze-dark-mode .list-group .list-group-item .h6 a:not(:hover) {
  color: #dee2e6;
}

.muze-dark-mode .navbar-light {
  background-color: #1e1e1e;
}

.muze-dark-mode .header-search form .input-group.bg-white.border-gray-300 {
  background-color: #1a1919 !important;
}

.muze-dark-mode .header-search .form-control {
  background-color: #1a1919 !important;
  color: #ced4da;
}

.muze-dark-mode .header-search .border-gray-300 {
  border-color: #1a1919 !important;
}

.muze-dark-mode .btn-outline-dark:not(:hover) {
  border-color: #adb5bd !important;
  color: #adb5bd !important;
}

.muze-dark-mode .btn-outline-dark:not(:hover) svg path {
  fill: #adb5bd;
}

.muze-dark-mode .btn-light {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
  color: #adb5bd;
}

.muze-dark-mode .btn-light:hover {
  background-color: #212529 !important;
  border-color: #212529 !important;
}

.muze-dark-mode .btn-light svg path {
  fill: #fff;
}

.muze-dark-mode .header-fixed {
  background-color: #1e1e1e;
}

.muze-dark-mode .bg-white,
.muze-dark-mode .bg-blue-50 {
  background-color: #1a1919 !important;
}

.muze-dark-mode .text-gray-600:not(a) {
  color: #adb5bd !important;
}

.muze-dark-mode .back-arrow.border-gray-200 {
  border-color: #1a1919 !important;
}

.muze-dark-mode .bg-gray-50 {
  background-color: #343a40 !important;
}

.muze-dark-mode .icon-blue-hover .grid-option > a svg path {
  fill: #fff;
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg {
  border-color: #343a40;
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
  color: #adb5bd;
}

.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link.nav-subtitle
  small,
.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link.nav-subtitle
  .small {
  color: #6c757d;
}

.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  svg
  path {
  fill: #adb5bd;
}

.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link:hover,
.muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active {
  color: #e9ecef;
}

.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link:hover:before,
.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link.active:before {
  background-color: #e9ecef !important;
}

.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link:hover
  svg
  path,
.muze-dark-mode
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link.active
  svg
  path {
  fill: #e9ecef;
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer {
  background-color: #1e1e1e;
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer.border-gray-50 {
  border-color: #343a40 !important;
}

.muze-dark-mode .navbar-vertical .navbar-vertical-footer-list > li a svg path {
  fill: #adb5bd;
}

.muze-dark-mode
  .navbar-vertical
  .navbar-vertical-footer-list
  > li
  a:hover
  svg
  path {
  fill: #e9ecef;
}

.muze-dark-mode .border-gray-200 {
  border-color: #343a40 !important;
}

.muze-dark-mode .display-1,
.muze-dark-mode .display-2,
.muze-dark-mode .display-3,
.muze-dark-mode .display-4,
.muze-dark-mode .display-5,
.muze-dark-mode .display-6,
.muze-dark-mode .h1,
.muze-dark-mode .h2,
.muze-dark-mode .h3,
.muze-dark-mode .h4,
.muze-dark-mode .h5,
.muze-dark-mode .h6,
.muze-dark-mode h1,
.muze-dark-mode .h1,
.muze-dark-mode h2,
.muze-dark-mode .h2,
.muze-dark-mode h3,
.muze-dark-mode .h3,
.muze-dark-mode h4,
.muze-dark-mode .h4,
.muze-dark-mode h5,
.muze-dark-mode .h5,
.muze-dark-mode h6,
.muze-dark-mode .h6 {
  color: #e9ecef;
}

.muze-dark-mode .card {
  background-color: #1a1919;
}

.muze-dark-mode .card.border-gray-50 {
  border-color: #343a40 !important;
}

.muze-dark-mode .card-table th {
  background-color: #343a40;
  border-color: #343a40 !important;
  color: #adb5bd;
}

.muze-dark-mode .card-table td {
  border-color: #343a40 !important;
  color: #adb5bd;
}

.muze-dark-mode .card-table td .bg-white {
  background-color: #fff !important;
}

.muze-dark-mode .card-table td .text-gray-700 {
  color: #e9ecef !important;
}

.muze-dark-mode .arrow-icon {
  -webkit-filter: brightness(7);
  filter: brightness(7);
}

.muze-dark-mode footer .navbar li a:not(:hover) {
  color: #adb5bd;
}

.muze-dark-mode .dropdown .btn-dark-100:hover {
  background-color: #0da2f5;
  color: #fff;
}

.muze-dark-mode .dropdown .btn-dark-100:hover svg path {
  fill: #fff;
}

.muze-dark-mode .dropdown-menu {
  background-color: #1e1e1e;
  border: 0.125rem solid #1a1919;
  box-shadow: none;
}

.muze-dark-mode .dropdown-menu .text-black-600 {
  color: #adb5bd;
}

.muze-dark-mode .dropdown-menu .dropdown-sub-title {
  color: #6c757d;
}

.muze-dark-mode .dropdown-menu .dropdown-divider {
  border-color: #343a40;
}

.muze-dark-mode .dropdown-menu .dropdown-item:not(.active):not(:active) {
  color: #e9ecef;
}

.muze-dark-mode .dropdown-menu .dropdown-item:hover,
.muze-dark-mode .dropdown-menu .dropdown-item:focus {
  background-color: #1a1919;
  color: #e9ecef !important;
}

.muze-dark-mode .dropdown-menu .dropdown-item svg path {
  fill: #adb5bd;
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item {
  border-color: #343a40;
  color: #adb5bd !important;
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item:hover {
  background-color: #1a1919;
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item.unread {
  background-color: #1e1e1e;
}

.muze-dark-mode
  .dropdown-menu
  .dropdown-body
  .dropdown-item.unread
  .dropdown-title {
  color: #ced4da;
}

.muze-dark-mode .dropdown-menu .dropdown-body .dropdown-item .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode .dropdown-menu .dropdown-footer {
  background-color: #1a1919;
}

.muze-dark-mode .dropdown-menu .border-top {
  border-color: #343a40 !important;
}

.muze-dark-mode .nav-tabs.nav-tabs-line {
  border-color: #343a40;
}

.muze-dark-mode .nav-tabs .nav-link {
  color: #adb5bd;
}

.muze-dark-mode .nav-tabs .nav-link.active {
  color: #e9ecef;
}

.muze-dark-mode .nav-tabs .nav-link:hover:not(.active) {
  border-color: #e9ecef;
  color: #e9ecef;
}

.muze-dark-mode .check-list .form-check-input:not(:checked) {
  border-color: #495057;
  background-color: transparent;
}

.muze-dark-mode .check-list .form-check-input:checked ~ .form-check-label {
  color: #6c757d;
}

.muze-dark-mode .check-list .form-check-label {
  color: #e9ecef;
}

.muze-dark-mode .checklist-box textarea.form-control {
  color: #e9ecef;
  background-color: transparent;
}

.muze-dark-mode
  .checklist-box
  textarea.form-control::-webkit-input-placeholder {
  color: #adb5bd;
}

.muze-dark-mode .checklist-box textarea.form-control::-moz-placeholder {
  color: #adb5bd;
}

.muze-dark-mode .checklist-box textarea.form-control:-ms-input-placeholder {
  color: #adb5bd;
}

.muze-dark-mode .checklist-box textarea.form-control:-moz-placeholder {
  color: #adb5bd;
}

.muze-dark-mode.project-management-template .card.border-gray-50 {
  border-color: transparent !important;
}

.muze-dark-mode.project-management-template .border-gray-300 {
  border-color: transparent !important;
}

.muze-dark-mode.project-management-template .text-black-600 {
  color: #adb5bd;
}

.muze-dark-mode.project-management-template .h1.text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.project-management-template .h1 ~ .text-gray-700 {
  color: #adb5bd !important;
}

.muze-dark-mode.project-management-template .bg-gray-100 {
  background-color: #1a1919 !important;
}

.muze-dark-mode.signup-simple-template.bg-white,
.muze-dark-mode.signup-template.bg-white {
  background-color: #1e1e1e !important;
}

.muze-dark-mode.signup-simple-template a.bg-white,
.muze-dark-mode.signup-template a.bg-white {
  background-color: #fff !important;
}

.muze-dark-mode.signup-simple-template a.text-gray-600,
.muze-dark-mode.signup-template a.text-gray-600 {
  color: #ced4da !important;
}

.muze-dark-mode.signup-simple-template a:hover,
.muze-dark-mode.signup-template a:hover {
  color: #0da2f5 !important;
}

.muze-dark-mode.signup-simple-template .bg-gray-100,
.muze-dark-mode.signup-template .bg-gray-100 {
  background-color: #1e1e1e !important;
}

.muze-dark-mode.signup-simple-template .dark-mode-image,
.muze-dark-mode.signup-template .dark-mode-image {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.muze-dark-mode.signup-simple-template .text-gray-600,
.muze-dark-mode.signup-template .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.signup-simple-template
  .text-gray-600
  a.text-gray-600:not(:hover),
.muze-dark-mode.signup-template .text-gray-600 a.text-gray-600:not(:hover) {
  color: inherit !important;
}

.muze-dark-mode.signup-simple-template hr.bg-gray-200,
.muze-dark-mode.signup-template hr.bg-gray-200 {
  background-color: #343a40 !important;
}

.muze-dark-mode.signup-simple-template .form-control,
.muze-dark-mode.signup-template .form-control {
  background-color: #343a40;
  border-color: #343a40;
  color: #ced4da;
}

.muze-dark-mode.signup-simple-template .form-check-input:not(:checked),
.muze-dark-mode.signup-template .form-check-input:not(:checked) {
  border-color: #ced4da;
  background-color: #1a1919;
}

.muze-dark-mode.signup-simple-template .form-check-label,
.muze-dark-mode.signup-template .form-check-label {
  color: #ced4da !important;
}

.muze-dark-mode.signup-simple-template
  .form-check-label
  .text-gray-600:not(:hover),
.muze-dark-mode.signup-template .form-check-label .text-gray-600:not(:hover) {
  color: inherit !important;
}

.muze-dark-mode.signup-simple-template .text-gray-700:not(a),
.muze-dark-mode.signup-template .text-gray-700:not(a) {
  color: #ced4da !important;
}

.muze-dark-mode.signup-simple-template
  .text-gray-700:not(a)
  a.text-gray-700:not(:hover),
.muze-dark-mode.signup-template
  .text-gray-700:not(a)
  a.text-gray-700:not(:hover) {
  color: inherit !important;
}

.muze-dark-mode.signup-simple-template .dark-logo,
.muze-dark-mode.signup-template .dark-logo {
  display: none;
}

.muze-dark-mode.signup-simple-template .white-logo,
.muze-dark-mode.signup-template .white-logo {
  display: inline-block;
}

.muze-dark-mode.festive-template .border-gray-50 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.festive-template .card.bg-gray-800 {
  background-color: #343a40 !important;
}

.muze-dark-mode.festive-template .card.bg-gray-800 .btn-dark-100:not(:hover) {
  background-color: #f8f9fa;
  color: #1e1e1e;
}

.muze-dark-mode.festive-template
  .card.bg-gray-800
  .btn-dark-100:not(:hover)
  svg
  path {
  fill: #1e1e1e;
}

.muze-dark-mode.festive-template .card.bg-indigo-light {
  background-color: #975cf6 !important;
}

.muze-dark-mode.festive-template
  .card.bg-indigo-light
  .btn-dark-100:not(:hover) {
  background-color: #f8f9fa;
  color: #1e1e1e;
}

.muze-dark-mode.festive-template
  .card.bg-indigo-light
  .btn-dark-100:not(:hover)
  svg
  path {
  fill: #1e1e1e;
}

.muze-dark-mode.festive-template .card.bg-teal-500 {
  background-color: #20c997 !important;
}

.muze-dark-mode.festive-template .card.bg-teal-500 .btn-dark-100:not(:hover) {
  background-color: #f8f9fa;
  color: #1e1e1e;
}

.muze-dark-mode.festive-template
  .card.bg-teal-500
  .btn-dark-100:not(:hover)
  svg
  path {
  fill: #1e1e1e;
}

.muze-dark-mode.festive-template .main-content .btn-dark {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
}

.muze-dark-mode.festive-template .main-content .btn-dark:hover {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.muze-dark-mode.festive-template .main-content .bg-gray-100 {
  background-color: #1a1919 !important;
}

.muze-dark-mode.festive-template .main-content .border-gray-300 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.festive-template .main-content .get-started-two img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.muze-dark-mode.settings-template .sidebar-nav li {
  border-color: #343a40;
}

.muze-dark-mode.settings-template .sidebar-nav li a {
  color: #e9ecef;
}

.muze-dark-mode.settings-template .sidebar-nav li a svg path {
  fill: #e9ecef;
}

.muze-dark-mode.settings-template .sidebar-nav li a.active,
.muze-dark-mode.settings-template .sidebar-nav li a:hover {
  background-color: #1a1919;
}

.muze-dark-mode.settings-template aside.border-gray-200 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.settings-template .border-gray-50 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.settings-template .form-control {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
}

.muze-dark-mode.settings-template .text-gray-700,
.muze-dark-mode.settings-template .text-black-600 {
  color: #ced4da !important;
}

.muze-dark-mode.settings-template .form-style-two ul li {
  color: #ced4da !important;
}

.muze-dark-mode.settings-template .main-content .media .avatar.bg-white {
  background-color: #fff !important;
}

.muze-dark-mode.settings-template .main-content .media .media-body span.fs-16 {
  color: #ced4da;
}

.muze-dark-mode.settings-template .form-switch .form-check-input:not(:checked) {
  background-color: #343a40;
  border-color: #343a40;
}

.muze-dark-mode.settings-template h6 img,
.muze-dark-mode.settings-template .h6 img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.muze-dark-mode.settings-template .card-body .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.billing-templete .border-gray-50 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.billing-templete span.text-gray-600,
.muze-dark-mode.billing-templete small.text-gray-600,
.muze-dark-mode.billing-templete .text-gray-600.small,
.muze-dark-mode.billing-templete p.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.billing-templete span.fs-16,
.muze-dark-mode.billing-templete small.fs-16,
.muze-dark-mode.billing-templete .fs-16.small,
.muze-dark-mode.billing-templete p.fs-16 {
  color: #e9ecef;
}

.muze-dark-mode.billing-templete .badge.bg-blue-50 {
  background-color: #e6f0ff !important;
}

.muze-dark-mode.billing-templete .invoice-table .table td {
  border-color: #343a40;
}

.muze-dark-mode.billing-templete .invoice-table .table td span.avatar-border {
  border-width: 0.125rem;
}

.muze-dark-mode.billing-templete footer p.text-gray-600 {
  color: #adb5bd !important;
}

.muze-dark-mode.invoice-template .border-gray-50 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.invoice-template .main-content .dark-logo {
  display: none;
}

.muze-dark-mode.invoice-template .main-content .white-logo {
  display: inline-block;
}

.muze-dark-mode.invoice-template .main-content p.text-gray-700,
.muze-dark-mode.invoice-template .main-content p.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.invoice-template .main-content .avatar-border {
  border-width: 0.125rem;
}

.muze-dark-mode.invoice-template .main-content .card-table td,
.muze-dark-mode.invoice-template .main-content .card-table th {
  color: #e9ecef;
}

.muze-dark-mode.invoice-template .main-content span.text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.invoice-template .main-content footer p.text-gray-600 {
  color: #adb5bd !important;
}

.muze-dark-mode.apikeys-template .border-gray-50 {
  border-color: #1a1919 !important;
}

.muze-dark-mode.apikeys-template .card.border-gray-200 {
  border: none !important;
  background-color: #1e1e1e;
}

.muze-dark-mode.apikeys-template .card-table td {
  color: #e9ecef;
}

.muze-dark-mode.apikeys-template .card-table td img {
  -webkit-filter: brightness(0) invert(0.8);
  filter: brightness(0) invert(0.8);
}

.muze-dark-mode.user-profile-template h6 a.text-black-600,
.muze-dark-mode.user-profile-template .h6 a.text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.user-profile-template .border-gray-50 {
  border: none !important;
}

.muze-dark-mode.user-profile-template p.text-gray-700,
.muze-dark-mode.user-profile-template p .text-gray-700 {
  color: #ced4da !important;
}

.muze-dark-mode.user-profile-template p svg path {
  fill: #ced4da;
}

.muze-dark-mode.user-profile-template .customize-sidebar p.text-gray-700,
.muze-dark-mode.user-profile-template .customize-sidebar p .text-gray-700 {
  color: #6c757d !important;
}

.muze-dark-mode.user-profile-template .customize-sidebar p svg path {
  fill: #6c757d;
}

.muze-dark-mode.user-profile-template span.text-gray-700 {
  color: #e9ecef !important;
}

.muze-dark-mode.user-profile-template span.text-gray-800 {
  color: #ced4da;
}

.muze-dark-mode.user-profile-template .progress {
  background-color: #343a40;
}

.muze-dark-mode.user-profile-template .profile-list li span + span {
  color: #ced4da;
}

.muze-dark-mode.user-profile-template .card-body .avatar.bg-white {
  background-color: #fff !important;
}

.muze-dark-mode.user-profile-template .media-body .text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.user-profile-template .media-body .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.user-profile-template
  .list-group
  .list-group-item
  h6
  a:not(:hover),
.muze-dark-mode.user-profile-template
  .list-group
  .list-group-item
  .h6
  a:not(:hover) {
  color: #e9ecef;
}

.muze-dark-mode.user-profile-template strong.text-black-600 {
  color: #e9ecef !important;
}

.muze-dark-mode.user-profile-template .card-text.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.user-profile-template .small.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.user-profile-template .small.text-gray-600 svg path {
  fill: #6c757d;
}

.muze-dark-mode.user-profile-template form .input-group.bg-white {
  background-color: #343a40 !important;
}

.muze-dark-mode.user-profile-template
  form
  .input-group.bg-white.border-gray-300 {
  border-color: #343a40 !important;
}

.muze-dark-mode.user-profile-template form .form-control {
  background-color: #343a40;
  color: #fff;
}

.muze-dark-mode.user-profile-template h5 a.text-black-600,
.muze-dark-mode.user-profile-template .h5 a.text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.user-profile-template .avatar-initials {
  color: #1e1e1e;
}

.muze-dark-mode.pricing-template .bg-blue-50 {
  background-color: #e6f0ff !important;
}

.muze-dark-mode.pricing-template .plan-list li {
  color: #e9ecef;
}

.muze-dark-mode.pricing-template
  .small.text-gray-600
  a.text-black-600:not(:hover) {
  color: #e9ecef;
}

.muze-dark-mode.pricing-template .bg-blue-50.alert-dismissible {
  background-color: #1a1919 !important;
}

.muze-dark-mode .muze-collapes .card {
  border-color: #343a40 !important;
  background-color: transparent;
}

.muze-dark-mode .muze-collapes .card .card-header .btn-light {
  color: #e9ecef;
}

.muze-dark-mode .muze-collapes .card .card-header .btn-light:after,
.muze-dark-mode .muze-collapes .card .card-header .btn-light:before {
  background-color: #e9ecef;
}

.muze-dark-mode .muze-collapes .card .card-body p.text-gray-700 {
  color: #ced4da !important;
}

.muze-dark-mode .search-less .search-left img {
  -webkit-filter: brightness(0) invert(0.7);
  filter: brightness(0) invert(0.7);
}

.muze-dark-mode .search-less .search-left .form-control {
  background-color: #1a1919 !important;
  color: #fff;
}

.muze-dark-mode .customize-sidebar {
  color: #1a1919;
}

.muze-dark-mode .customize-sidebar .display-1,
.muze-dark-mode .customize-sidebar .display-2,
.muze-dark-mode .customize-sidebar .display-3,
.muze-dark-mode .customize-sidebar .display-4,
.muze-dark-mode .customize-sidebar .display-5,
.muze-dark-mode .customize-sidebar .display-6,
.muze-dark-mode .customize-sidebar .h1,
.muze-dark-mode .customize-sidebar .h2,
.muze-dark-mode .customize-sidebar .h3,
.muze-dark-mode .customize-sidebar .h4,
.muze-dark-mode .customize-sidebar .h5,
.muze-dark-mode .customize-sidebar .h6,
.muze-dark-mode .customize-sidebar h1,
.muze-dark-mode .customize-sidebar .h1,
.muze-dark-mode .customize-sidebar h2,
.muze-dark-mode .customize-sidebar .h2,
.muze-dark-mode .customize-sidebar h3,
.muze-dark-mode .customize-sidebar .h3,
.muze-dark-mode .customize-sidebar h4,
.muze-dark-mode .customize-sidebar .h4,
.muze-dark-mode .customize-sidebar h5,
.muze-dark-mode .customize-sidebar .h5,
.muze-dark-mode .customize-sidebar h6,
.muze-dark-mode .customize-sidebar .h6 {
  color: #1e1e1e;
}

.muze-dark-mode .customize-sidebar .btn-light {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #1e1e1e;
}

.muze-dark-mode .customize-sidebar .btn-light:hover {
  background-color: #e6f0ff !important;
  border-color: #e6f0ff !important;
}

.muze-dark-mode .customize-sidebar .btn-light:hover svg path {
  fill: #0da2f5;
}

.muze-dark-mode .customize-sidebar .btn-light svg path {
  fill: #1e1e1e;
}

.muze-dark-mode .customize-sidebar .border-gray-200 {
  border-color: #e9ecef !important;
}

.muze-dark-mode .customize-sidebar .bg-white {
  background-color: #fff !important;
}

.muze-dark-mode .customize-sidebar .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode .customize-sidebar .btn-outline-dark:not(:hover) {
  border-color: #212529 !important;
  color: #212529 !important;
}

.muze-dark-mode.help-center-template
  .main-content
  h5
  a.text-black-600:not(:hover),
.muze-dark-mode.help-center-template
  .main-content
  .h5
  a.text-black-600:not(:hover) {
  color: #e9ecef;
}

.muze-dark-mode.help-center-template .main-content p.text-gray-700,
.muze-dark-mode.help-center-template .main-content p.text-black-600 {
  color: #ced4da !important;
}

.muze-dark-mode.help-center-template .main-content form .form-control,
.muze-dark-mode.help-center-template .main-content form .form-select {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
}

.muze-dark-mode.help-center-template
  .main-content
  form
  .form-check
  .form-check-input:not(:checked) {
  background-color: transparent;
  border-color: #adb5bd;
}

.muze-dark-mode.help-center-template
  .main-content
  form
  .form-check
  .form-check-label {
  color: #ced4da;
}

.muze-dark-mode.empty-state-template p.text-black-600 {
  color: #6c757d;
}

.muze-dark-mode .chat-header a.circle:not(:hover) {
  background-color: #343a40;
}

.muze-dark-mode .chat-header a.circle:not(:hover) svg path {
  fill: #e9ecef;
}

.muze-dark-mode.chat-template .nav.nav-pills .nav-link {
  color: #e9ecef;
}

.muze-dark-mode.chat-template .message-box {
  background-color: #343a40;
}

.muze-dark-mode.chat-template .message-box:before {
  border-right: 1.25rem solid #343a40;
}

.muze-dark-mode.chat-template .message-box p {
  color: #e9ecef;
}

.muze-dark-mode.chat-template .chat-list a {
  background-color: #1e1e1e;
}

.muze-dark-mode.chat-template .chat-list a.active {
  background-color: #1a1919;
}

.muze-dark-mode.chat-template .chat-list a.unread {
  background-color: #343a40;
}

.muze-dark-mode.chat-template .chat-list a.unread h6,
.muze-dark-mode.chat-template .chat-list a.unread .h6 {
  color: #e9ecef;
}

.muze-dark-mode.chat-template .chat-list a p {
  color: #adb5bd;
}

.muze-dark-mode.chat-template .chat-list a .media-body span.text-muted {
  color: #6c757d !important;
}

.muze-dark-mode.chat-template .attachment {
  background-color: #343a40;
  color: #e9ecef;
}

.muze-dark-mode.chat-template .attachment svg path {
  fill: #e9ecef;
}

.muze-dark-mode.chat-template .attachment:hover {
  color: #0da2f5;
}

.muze-dark-mode.chat-template .attachment:hover svg path {
  fill: #0da2f5;
}

.muze-dark-mode.chat-template .chat-items hr.bg-gray-200 {
  background-color: #343a40;
}

.muze-dark-mode.chat-template .chat-items span.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.chat-template .chat-message .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.chat-template .message-sender .message-box {
  background-color: #0da2f5;
}

.muze-dark-mode.chat-template .message-sender .message-box:before {
  border-right-color: #0da2f5;
}

.muze-dark-mode.chat-template .message-footer .form-control {
  background-color: transparent;
  color: #e9ecef;
}

.muze-dark-mode.chat-template .message-footer a:not(:hover) svg path {
  fill: #e9ecef;
}

.muze-dark-mode.file-manager-template figure.border-gray-200 {
  border: none !important;
}

.muze-dark-mode.file-manager-template figure .arrow-icon {
  -webkit-filter: brightness(4);
  filter: brightness(4);
}

.muze-dark-mode.file-manager-template .text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.file-manager-template .badge-soft-primary {
  background-color: #343a40;
  color: #fff;
}

.muze-dark-mode.calendar-template .fc-theme-standard td,
.muze-dark-mode.calendar-template .fc-theme-standard th,
.muze-dark-mode.calendar-template .fc-theme-standard .fc-scrollgrid {
  border-color: #343a40;
}

.muze-dark-mode.calendar-template .calendar-box a {
  color: #ced4da;
}

.muze-dark-mode.all-projects-template .nav-pills .nav-link {
  color: #e9ecef;
}

.muze-dark-mode.all-projects-template form .input-group.border-gray-300 {
  border-color: #343a40 !important;
}

.muze-dark-mode.all-projects-template form .input-group.bg-white {
  background-color: #343a40 !important;
}

.muze-dark-mode.all-projects-template form .input-group img {
  -webkit-filter: brightness(2);
  filter: brightness(2);
}

.muze-dark-mode.all-projects-template form .input-group .form-control {
  background-color: inherit;
}

.muze-dark-mode.all-projects-template
  form
  .input-group
  .form-control::-webkit-input-placeholder {
  color: #ced4da;
}

.muze-dark-mode.all-projects-template
  form
  .input-group
  .form-control::-moz-placeholder {
  color: #ced4da;
}

.muze-dark-mode.all-projects-template
  form
  .input-group
  .form-control:-ms-input-placeholder {
  color: #ced4da;
}

.muze-dark-mode.all-projects-template
  form
  .input-group
  .form-control:-moz-placeholder {
  color: #ced4da;
}

.muze-dark-mode.all-projects-template .card-body a.text-black-600:not(:hover) {
  color: #e9ecef;
}

.muze-dark-mode.all-projects-template .group-cards .card.border-gray-200 {
  border: none !important;
}

.muze-dark-mode.all-projects-template .group-cards .card span.text-gray-700 {
  color: #e9ecef !important;
}

.muze-dark-mode.all-projects-template span.text-gray-600.small {
  color: #e9ecef !important;
}

.muze-dark-mode.all-projects-template .badge.bg-gray-200 {
  background-color: #343a40 !important;
}

.muze-dark-mode.all-projects-template .badge.bg-gray-200.text-gray-700 {
  color: #adb5bd !important;
}

.muze-dark-mode .step-list li:not(:first-child):not(.active):before {
  background-color: #343a40;
}

.muze-dark-mode .step-list li:not(.active) .circle {
  background-color: #495057;
}

.muze-dark-mode.new-projects-template .card.border-gray-200 {
  border: none !important;
}

.muze-dark-mode.new-projects-template .form-label {
  color: #e9ecef;
}

.muze-dark-mode.new-projects-template .form-control {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: #e9ecef;
}

.muze-dark-mode .ql-toolbar.ql-snow {
  border-color: #1e1e1e;
  background-color: #1e1e1e;
}

.muze-dark-mode .ql-container.ql-snow {
  border-color: #1e1e1e;
  background-color: #1e1e1e;
  color: #e9ecef;
}

.muze-dark-mode .ql-editor .ql-blank {
  color: #adb5bd;
}

.muze-dark-mode .ql-formats button:not(:hover) svg path {
  stroke: #e9ecef;
}

.muze-dark-mode .ql-formats button:not(:hover) .ql-stroke {
  stroke: #e9ecef;
}

.muze-dark-mode .ql-formats button:not(:hover) .ql-fill {
  fill: #e9ecef;
}

.muze-dark-mode.project-details-template .main-content .card.border-gray-200 {
  border: none !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .card
  .card-body
  a.text-black-600 {
  color: #e9ecef;
}

.muze-dark-mode.project-details-template
  .main-content
  .card
  .card-body
  span.text-gray-700 {
  color: #e9ecef !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .card
  .card-body
  p.text-gray-600:not(.card-text) {
  color: #6c757d !important;
}

.muze-dark-mode.project-details-template .main-content .card.border-gray-50 {
  border: none !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  .avatar-border-xs {
  border-color: transparent;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  p.text-gray-600 {
  color: #6c757d !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  span.text-gray-800 {
  color: #ced4da !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  strong.text-black-600 {
  color: #e9ecef !important;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  .link-primary:hover {
  color: #ced4da;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  .link-primary:hover
  svg
  path {
  fill: #ced4da;
}

.muze-dark-mode.project-details-template
  .main-content
  .list-group
  .list-group-item
  figure.border-gray-200 {
  border: none !important;
  background-color: #1e1e1e;
}

.muze-dark-mode .activity-list .list-group-item:not(:last-child):before {
  background-color: #343a40;
}

/* 20. Muze RTL CSS */
.muze-rtl ul {
  padding-right: 0;
}

.muze-rtl .main-content {
  margin-left: 0;
  margin-right: 16.0625rem;
}

.muze-rtl .header-fixed {
  left: 0;
  right: 16.0625rem;
}

.muze-rtl .avatar-group .avatar + .avatar {
  margin-left: 0;
  margin-right: -1.4375rem;
}

.muze-rtl .avatar-group .avatar-lg + .avatar-lg {
  margin-left: 0;
  margin-right: -1.25rem;
}

.muze-rtl .avatar-group .avatar-xs + .avatar-xs {
  margin-left: 0;
  margin-right: -0.625rem;
}

.muze-rtl .end-0 {
  left: 0 !important;
  right: auto !important;
}

.muze-rtl .navbar-vertical.navbar-expand-lg {
  border-left: 0.0625rem solid #e9ecef;
  border-right: none;
}

.muze-rtl
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link[data-bs-toggle="collapse"]:after {
  margin-left: 0;
  margin-right: auto;
}

.muze-rtl
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  .docs-version {
  margin-left: 0;
  margin-right: 0.5rem;
}

.muze-rtl
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav-link {
  padding-left: 2rem;
  padding-right: 3.8rem;
}

.muze-rtl
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav
  .nav-link {
  padding-left: 2rem;
  padding-right: 4.8rem;
}

.muze-rtl
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav
  .nav-link:before {
  margin-right: 0;
  margin-left: 0.4375rem;
}

.muze-rtl .navbar-vertical .navbar-vertical-footer-list {
  padding-right: 0;
}

.muze-rtl .dropdown-menu[style] {
  left: 0 !important;
}

.muze-rtl .dropdown-menu.dropdown-menu-end {
  left: 0 !important;
  right: auto;
}

.muze-rtl .dropdown-menu li {
  text-align: right;
}

.muze-rtl .dropdown-menu .dropdown-item .media-body {
  text-align: right;
}

.muze-rtl [class*="me-"]:not(.align-items-center) {
  margin-right: 0 !important;
}

.muze-rtl [class*="ms-"]:not(.align-items-center) {
  margin-left: 0 !important;
}

.muze-rtl [class*="ms-"]:not(.align-items-center).ms-auto {
  margin-right: auto !important;
}

.muze-rtl .text-end {
  text-align: left !important;
}

.muze-rtl .form-control {
  text-align: right;
}

.muze-rtl .form-check {
  padding-left: 0;
  margin-right: 0;
}

.muze-rtl .form-check .form-check-input {
  margin-left: 0;
  float: right;
}

.muze-rtl .form-switch {
  padding-left: 0;
}

.muze-rtl .end-16 {
  left: 1rem;
  right: auto;
}

.muze-rtl .get-started-img {
  right: auto;
  left: 3.125rem;
}

.muze-rtl .arrow-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl .get-startedtwo {
  left: 1.875rem;
  right: auto;
}

.muze-rtl .header-body .back-arrow svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl .header-body .icon-blue-hover.ps-0 {
  padding-left: calc(var(--bs-gutter-x) / 2) !important;
}

.muze-rtl .header-body .notification {
  margin-right: 0 !important;
}

.muze-rtl .header-body .notification .status {
  right: -0.4375rem;
}

.muze-rtl .header-body .header-search .form-control {
  background-color: transparent;
}

.muze-rtl.muze-dark-mode .navbar-vertical.navbar-expand-lg {
  border-color: #343a40;
}

.muze-rtl.muze-dark-mode .message-box:before {
  border-right: none;
  border-left: 1.25rem solid #343a40;
}

.muze-rtl.sidebar-icons .main-content {
  margin-left: 0;
  margin-right: 5rem;
}

.muze-rtl.sidebar-icons .header-fixed {
  left: 0;
  right: 5rem;
}

.muze-rtl.sidebar-icons .back-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  .ms-2 {
  margin-right: 0 !important;
}

.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  left: auto;
  right: 5rem;
}

.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box
  .nav-link {
  padding-left: 0.9375rem;
  padding-right: 1.5625rem;
}

.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  .status {
  left: 0;
  right: 0.375rem;
}

.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav-link {
  padding: 0.5rem 0.9375rem;
}

.muze-rtl.sidebar-compact .main-content {
  margin-left: 0;
  margin-right: 9.0625rem;
}

.muze-rtl.sidebar-compact .back-arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl.sidebar-compact .header-fixed {
  left: 0;
  right: 9.0625rem;
}

.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .nav-link
  .ms-2 {
  margin-right: 0 !important;
}

.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  left: auto;
  right: 9.0625rem;
}

.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box
  .nav-link {
  padding-left: 0.9375rem;
  padding-right: 1.5625rem;
}

.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-link
  .status {
  left: 0;
  right: -0.5rem;
}

.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav
  .nav-item
  .nav-link {
  padding: 0.5rem 0.9375rem;
}

.muze-rtl .customize-sidebar {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  right: auto;
  left: 0;
}

.muze-rtl.customize-box .customize-sidebar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.muze-rtl footer .navbar li {
  padding-left: 0;
  padding-right: 3.125rem;
}

.muze-rtl .text-md-end {
  text-align: left !important;
}

.muze-rtl .start-50 {
  left: auto !important;
  right: 50% !important;
}

.muze-rtl .translate-middle {
  -webkit-transform: translate(50%, -50%) !important;
  transform: translate(50%, -50%) !important;
}

.muze-rtl .chat-message {
  padding-left: 0;
  padding-right: 1.5625rem;
}

.muze-rtl .message-box:before {
  left: auto;
  right: -1.125rem;
  border-right: none;
  border-left: 1.25rem solid #f8f9fa;
}

.muze-rtl .message-sender .message-box:before {
  border-left: 1.25rem solid #0da2f5;
}

.muze-rtl .message-sender .message-files {
  padding-left: 0;
  padding-right: 4.6875rem;
}

.muze-rtl .message-sender .message-files ul {
  padding-right: 0;
}

.muze-rtl .message-footer a:not(.btn) {
  margin-right: 0;
  margin-left: 2.5rem;
}

.muze-rtl .chat-list a .unread-status {
  right: auto;
  left: 0;
}

.muze-rtl .chat-list a p {
  padding-right: 0;
  padding-left: 3.4375rem;
}

.muze-rtl .checklist-box textarea.form-control {
  padding-left: 0.9375rem;
  padding-right: 0;
}

.muze-rtl.file-manager-template .dropdown.end-0 {
  right: auto !important;
  left: 0.5rem !important;
}

.muze-rtl.file-manager-template .arrow-icon {
  -webkit-transform: none;
  transform: none;
}

.muze-rtl.file-manager-template .alert-dismissible .arrow-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl .ms-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.muze-rtl .muze-collapes .card .card-header .btn-light {
  padding-left: 2rem !important;
  padding-right: 0 !important;
  text-align: right !important;
}

.muze-rtl .muze-collapes .card .card-header .btn-light:after {
  right: auto;
  left: 0;
}

.muze-rtl .muze-collapes .card .card-header .btn-light:before {
  right: auto;
  left: 0.5625rem;
}

.muze-rtl .form-select {
  background-position: left 0.5rem center;
  padding-left: 1.75rem;
  padding-right: 0.75rem;
}

.muze-rtl.help-center-template .form-check .form-check-input {
  margin-left: 0.625rem;
}

.muze-rtl.pricing-template .badge.position-absolute.end-0 {
  right: auto !important;
  left: 1rem !important;
}

.muze-rtl .ql-editor {
  text-align: right;
}

.muze-rtl .step-list li:not(:first-child):before {
  left: auto;
  right: -50%;
}

.muze-rtl .activity-list .list-group-item:not(:last-child):before {
  left: auto;
  right: 4.0625rem;
}

.muze-rtl .get-startedthree {
  right: auto;
  left: -18.4375rem;
}

.muze-rtl .sidebar-nav li a {
  border-left: none;
  border-right: 0.25rem solid transparent;
}

.muze-rtl .sidebar-nav li a:hover,
.muze-rtl .sidebar-nav li a.active {
  border-color: #0da2f5;
}

.muze-rtl .ms-md-4 {
  margin-left: 0 !important;
}

.muze-rtl .btn-link svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl .profile-list li span + span {
  padding-left: 0;
  padding-right: 0.75rem;
}

.muze-rtl.top-header .navbar {
  border-left: none;
  border-right: 0.0625rem solid #e9ecef;
  padding-left: 0;
  padding-right: 0.625rem;
  margin-left: 0;
  margin-right: 1.5rem;
}

.muze-rtl.top-header .header .customize-btn .ps-2,
.muze-rtl.double-header .header .customize-btn .ps-2 {
  padding-left: 0 !important;
}

.muze-rtl.top-header .main-content,
.muze-rtl.double-header .main-content {
  margin-right: 0;
}

.muze-rtl.top-header .header-fixed,
.muze-rtl.double-header .header-fixed {
  right: 0;
}

.muze-rtl.top-header .header-body .icon-blue-hover.ps-0,
.muze-rtl.double-header .header-body .icon-blue-hover.ps-0 {
  padding-left: 0 !important;
}

.muze-rtl.top-header
  .header-body
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.muze-rtl.double-header
  .header-body
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: 0;
  margin-left: 0;
}

.muze-rtl.top-header
  .navbar
  .nav-item
  .nav-link[data-bs-toggle="collapse"]:after,
.muze-rtl.double-header
  .navbar
  .nav-item
  .nav-link[data-bs-toggle="collapse"]:after {
  margin-left: 0;
  margin-right: 0.8125rem;
}

.muze-rtl.top-header .navbar .nav-item .nav-link .docs-version,
.muze-rtl.double-header .navbar .nav-item .nav-link .docs-version {
  margin-left: 0;
  margin-right: 0.375rem;
}

.muze-rtl.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .nav-link:after,
.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .nav-link:after {
  margin-left: 0;
  margin-right: auto;
}

.muze-rtl.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box,
.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box {
  padding-left: 0;
  padding-right: 0.625rem;
}

.muze-rtl.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav,
.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav {
  padding: 0;
}

.muze-rtl.top-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link:before,
.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav-link:before {
  margin-right: 0;
  margin-left: 0.4375rem;
}

.muze-rtl.double-header .header-search .input-group {
  border-left: none !important;
  border-right: 0.0625rem solid #495057 !important;
  padding-left: 0.25rem !important;
  padding-right: 1.5625rem !important;
}

.muze-rtl.double-header .navbar {
  padding-left: 0;
  padding-right: 0.625rem;
}

.muze-rtl.double-header .navbar .nav-item .collapse-box {
  left: auto;
  right: 0;
}

.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box {
  padding-left: 0;
  padding-right: 0.625rem;
}

.muze-rtl.double-header
  .navbar
  .nav-item
  .collapse-box
  .nav
  .nav-item
  .collapse-box
  .nav {
  padding: 0;
}

.muze-rtl.double-header .header-body > .row {
  margin-right: 0;
}

.muze-rtl.festive-template .main-content .border-end {
  border-right: none !important;
}

.muze-rtl.billing-templete .media .ps-xxl-5 {
  padding-left: calc(var(--bs-gutter-x) / 2) !important;
}

.muze-rtl.billing-templete .media .ps-xxl-5 .d-flex.ps-xxl-5 {
  padding-left: 0 !important;
}

.muze-rtl .plan-list li span {
  padding-left: 0;
  padding-right: 0.8125rem;
}

.muze-rtl.pricing-template .btn-soft-primary svg,
.muze-rtl.pricing-template .btn-primary svg,
.muze-rtl.empty-state-template .btn-soft-primary svg,
.muze-rtl.empty-state-template .btn-primary svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.muze-rtl .sidebar-style .form-check {
  padding-right: 0;
}

.muze-rtl .sidebar-style .form-check-input {
  right: 1.5em;
  left: auto;
}

.muze-rtl .sidebar-style .form-check-label span {
  padding-left: 0;
  padding-right: 1.5em;
}

/* 21. Muze Container Layout CSS */
body.container .bg-blue-50.p-md-5 {
  padding: 1.5rem !important;
}

body.container:not(.top-header):not(.double-header) .header-fixed {
  left: auto;
  right: auto;
  position: static;
}

body.container:not(.top-header):not(.double-header) .main-content {
  margin-top: 0;
}

body.container:not(.top-header):not(.double-header)
  .navbar-vertical.navbar-expand-lg {
  background-color: transparent;
  border-left: 0.0625rem solid #e9ecef;
}

body.container:not(.top-header):not(.double-header)
  .navbar-vertical
  .navbar-vertical-footer {
  background-color: transparent;
}

body.container:not(.top-header):not(.double-header).muze-rtl
  .navbar-vertical.navbar-expand-lg {
  border-right: 0.0625rem solid #e9ecef;
}

body.container:not(.top-header):not(.double-header).muze-rtl .muze-chats {
  border-right: none;
  border-left: 0.0625rem solid #e9ecef;
}

body.container:not(.top-header):not(.double-header).muze-rtl .border-end {
  border-right: none !important;
}

body.container:not(.top-header):not(.double-header).muze-dark-mode
  .navbar-vertical.navbar-expand-lg {
  border-left: 0.0625rem solid #343a40;
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl
  .navbar-vertical.navbar-expand-lg {
  border-right: 0.0625rem solid #343a40;
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl
  .muze-chats {
  border-right: none;
  border-left: 0.0625rem solid #343a40;
}

body.container:not(.top-header):not(.double-header).muze-dark-mode.muze-rtl
  .border-end {
  border-right: none !important;
}

body.container:not(.muze-rtl) .main-content .p-xxl-5,
body.container:not(.muze-rtl) .main-content .px-xxl-5 {
  padding-right: 0 !important;
}

body.container.muze-rtl .main-content .p-xxl-5,
body.container.muze-rtl .main-content .px-xxl-5 {
  padding-left: 0 !important;
}

body.container.muze-rtl.top-header .icon-blue-hover .ms-xxl-5,
body.container.muze-rtl.double-header .icon-blue-hover .ms-xxl-5 {
  margin-right: 1.5rem !important;
}

body.container.muze-rtl.top-header .navbar,
body.container.muze-rtl.double-header .navbar {
  margin-right: 0;
  padding-right: 0;
}

body.container.muze-rtl.top-header .main-content .header .px-xxl-5,
body.container.muze-rtl.double-header .main-content .header .px-xxl-5 {
  padding-right: 0 !important;
}

body.container.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  right: auto;
  margin-right: 9.0625rem;
}

body.container.muze-rtl.sidebar-compact
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box {
  margin-right: 0;
}

body.container.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  right: auto;
  margin-right: 5rem;
}

body.container.muze-rtl.sidebar-icons
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box {
  margin-right: 0;
}

body.container.muze-rtl.project-management-template .get-startedthree {
  left: -27rem;
}

body.container.top-header .main-content > .p-xxl-5,
body.container.top-header .main-content > .px-xxl-5,
body.container.double-header .main-content > .p-xxl-5,
body.container.double-header .main-content > .px-xxl-5 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

body.container.top-header .main-content .header .px-xxl-5,
body.container.double-header .main-content .header .px-xxl-5 {
  padding-left: 0 !important;
}

body.container.top-header .main-content .header .customize-btn,
body.container.double-header .main-content .header .customize-btn {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
}

body.container.top-header .main-content .header .customize-btn svg,
body.container.double-header .main-content .header .customize-btn svg {
  margin-top: -0.125rem;
}

body.container.top-header .main-content .header .customize-btn span,
body.container.double-header .main-content .header .customize-btn span {
  display: none;
}

body.container.top-header .main-content .header .container-fluid,
body.container.top-header .main-content .header .container-sm,
body.container.top-header .main-content .header .container-md,
body.container.top-header .main-content .header .container-lg,
body.container.top-header .main-content .header .container-xl,
body.container.top-header .main-content .header .container-xxl,
body.container.double-header .main-content .header .container-fluid,
body.container.double-header .main-content .header .container-sm,
body.container.double-header .main-content .header .container-md,
body.container.double-header .main-content .header .container-lg,
body.container.double-header .main-content .header .container-xl,
body.container.double-header .main-content .header .container-xxl {
  max-width: 82.5rem;
  padding-right: var(--bs-gutter-x, 0.75rem) !important;
  padding-left: var(--bs-gutter-x, 0.75rem) !important;
  margin-right: auto;
  margin-left: auto;
}

body.container.top-header.festive-template
  .main-content
  .col-xxl-9
  > .p-xxl-5.border-end,
body.container.double-header.festive-template
  .main-content
  .col-xxl-9
  > .p-xxl-5.border-end {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body.container.top-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5,
body.container.double-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
  margin-left: 1.5rem !important;
}

body.container.top-header:not(.muze-rtl) .navbar,
body.container.double-header:not(.muze-rtl) .navbar {
  margin-left: 0.625rem;
}

body.container.top-header .navbar .nav-item .nav-link,
body.container.double-header .navbar .nav-item .nav-link {
  padding: 0.6875rem 0.75rem;
}

body.container.double-header .header:before {
  content: "";
  background-color: #1a1919;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 6rem;
}

body.container.double-header .header .navbar {
  padding-left: 0;
  margin-left: 0;
}

body.container.double-header .double-header-nav {
  padding-left: 0;
  padding-right: 0;
}

body.container.double-header .double-header-nav .navbar-expand-lg .navbar-nav {
  margin: 0 -0.75rem;
}

body.container.sidebar-compact:not(.muze-rtl)
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  left: auto;
  margin-left: 9.0625rem;
}

body.container.sidebar-compact:not(.muze-rtl)
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box {
  margin-left: 0;
}

body.container.sidebar-icons:not(.muze-rtl)
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box {
  left: auto;
  margin-left: 5rem;
}

body.container.sidebar-icons:not(.muze-rtl)
  .navbar-vertical.navbar-expand-lg
  .navbar-nav
  .nav-item
  > .collapse-box
  .collapse-box {
  margin-left: 0;
}

body.container.muze-dark-mode.top-header .main-content .header .px-xxl-5 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body.container.muze-dark-mode.top-header .header-body > .row {
  margin-right: 0;
}

body.container.muze-dark-mode .muze-chats {
  border-right: 0.0625rem solid #343a40;
}

body.container.project-management-template .main-content .bg-gray-100.p-md-5 {
  padding-left: 1rem !important;
  padding-right: 1.5rem !important;
}

body.container.project-management-template
  .main-content
  .bg-gray-100.p-md-5
  .col-xl-6 {
  width: 60%;
}

body.container.project-management-template:not(.muze-rtl) .get-startedthree {
  right: -27rem;
}

body.container .muze-chats {
  border-right: 0.0625rem solid #e9ecef;
}

body.container .bg-blue-50.alert-dismissible .ps-xl-3 h2,
body.container .bg-blue-50.alert-dismissible .ps-xl-3 .h2 {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media screen and (min-width: 1400px) {
  body.container .main-content .col-xxl-9 {
    width: 66.667%;
  }
  body.container .main-content .col-xxl-3 {
    width: 33.333%;
  }
  body.container .bg-blue-50.alert-dismissible .ps-xl-3 {
    padding-left: 0 !important;
  }
  body.container .bg-blue-50.alert-dismissible .col-xxl-4 {
    width: 50%;
  }
  body.container.analytics-template .col-xxl-3 {
    width: 50%;
  }
  body.container.apikeys-template .main-content .col-xxl-4 {
    width: 50%;
  }
  body.container.user-profile-template .col-xxl-2 {
    width: 25%;
  }
  body.container.user-profile-template .px-md-4 {
    padding-left: 0.65rem !important;
    padding-right: 0.65rem !important;
  }
  body.container.user-profile-template .row.ps-xl-3 {
    padding-left: 0 !important;
  }
  body.container.user-profile-template .btn.m-md-3 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  body.container.user-profile-template .card-body .p-xl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  body.container.team-template .card-body .px-md-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  body.container.pricing-template .col-xxl-6.p-md-5,
  body.container.help-center-template .col-xxl-6.p-md-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  body.container.pricing-template .h-100.px-lg-5,
  body.container.help-center-template .h-100.px-lg-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  body.container.pricing-template .after-header ~ .px-xxl-5,
  body.container.help-center-template .after-header ~ .px-xxl-5 {
    padding-right: 3rem !important;
  }
  body.container.pricing-template.muze-rtl .after-header ~ .px-xxl-5,
  body.container.help-center-template.muze-rtl .after-header ~ .px-xxl-5 {
    padding-left: 3rem !important;
  }
  body.container.file-manager-template .has-xxl-5 {
    width: 33.333%;
  }
  body.container.chat-template .muze-chats .px-xxl-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  body.container.festive-template .main-content .col-xxl-9 {
    width: 100%;
  }
  body.container.festive-template .main-content .col-xxl-9 > .p-xxl-5 {
    padding-bottom: 0 !important;
  }
  body.container.festive-template
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    border: none !important;
  }
  body.container.festive-template .main-content .col-xxl-3 {
    width: 100%;
  }
  body.container.festive-template footer {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1650px) {
  .festive-template .p-xxl-5 {
    padding: 1.5rem !important;
  }
  .festive-template .px-xxl-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .muze-rtl.top-header .icon-blue-hover .ms-xxl-5 {
    margin-right: 1.2rem !important;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1599px) {
  .festive-template .col-xxl-9 {
    width: 70%;
  }
  .festive-template .col-xxl-3 {
    width: 30%;
  }
  .h3,
  h3,
  .h3 {
    font-size: 1.5rem;
  }
  .title-box {
    min-height: 4.6875rem;
  }
  .nav.nav-pills .nav-link {
    padding: 0.5rem 1.2rem;
  }
  .chat-items {
    height: calc(100vh - 22.25rem);
  }
  .muze-rtl.top-header .icon-blue-hover .ms-xxl-5 {
    margin-right: 1.2rem !important;
  }
  .muze-rtl.top-header .navbar {
    padding-right: 0.625rem;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0.675rem;
  }
  .muze-rtl.top-header .navbar .nav-item .nav-link {
    padding: 0.6875rem;
  }
  .double-header:not(.muze-rtl) .navbar,
  .top-header:not(.muze-rtl) .navbar {
    margin-left: 1rem;
  }
  .double-header:not(.muze-rtl) .navbar .nav-item .nav-link,
  .top-header:not(.muze-rtl) .navbar .nav-item .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .double-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5,
  .top-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
    margin-left: 1.6rem !important;
  }
}

@media screen and (min-width: 1520px) {
  .has-xxl-5 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 20%;
  }
  .has-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 29.0625rem;
  }
  .has-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(100% - 29.0625rem);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1519px) {
  .get-started-two {
    max-width: 15rem;
  }
  .has-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 25.3125rem;
  }
  .has-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(100% - 25.3125rem);
  }
  body.container.project-details-template .card-body .btn-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media screen and (max-width: 1399px) {
  .chat-list a {
    padding: 1.125rem 1rem 1.125rem 0.6875rem;
  }
  .chat-items {
    height: calc(100vh - 21.25rem);
    padding: 0.9375rem 0;
  }
  .message-sender {
    padding-left: 1.25rem;
  }
  .chat-template .nav.nav-pills .nav-link {
    padding: 0.5rem 1.2rem;
  }
  .top-header .navbar {
    padding-left: 0;
    margin-left: 0;
  }
  .top-header .navbar .nav-item .nav-link {
    padding: 0.6875rem;
    font-size: 0.8rem;
  }
  .top-header .navbar .nav-item .nav-link[data-bs-toggle="collapse"]:after {
    margin-left: 0.5625rem;
  }
  .top-header .header .customize-btn span {
    font-size: 0;
    padding-left: 0 !important;
  }
  .top-header .header .customize-btn.btn-lg,
  .top-header .header .btn-group-lg > .customize-btn.btn {
    padding: 0.5rem 1rem;
  }
  .double-header .navbar {
    padding-left: 0;
  }
  .double-header .chat-items {
    height: calc(100vh - 25.25rem);
  }
  .muze-rtl.double-header .header-body > .row {
    margin-right: 0;
  }
  .muze-rtl.double-header .navbar {
    padding-right: 0;
  }
  .muze-rtl.top-header .navbar {
    margin-right: 0.5rem;
  }
  .muze-rtl.top-header .header-body > .row {
    margin-right: 0;
  }
  .muze-rtl.top-header .header .customize-btn .ps-2 {
    padding-right: 0 !important;
  }
  body.container.top-header .main-content .header .container-fluid,
  body.container.top-header .main-content .header .container-sm,
  body.container.top-header .main-content .header .container-md,
  body.container.top-header .main-content .header .container-lg,
  body.container.top-header .main-content .header .container-xl,
  body.container.top-header .main-content .header .container-xxl,
  body.container.double-header .main-content .header .container-fluid,
  body.container.double-header .main-content .header .container-sm,
  body.container.double-header .main-content .header .container-md,
  body.container.double-header .main-content .header .container-lg,
  body.container.double-header .main-content .header .container-xl,
  body.container.double-header .main-content .header .container-xxl {
    max-width: 71.25rem;
  }
  body.container.top-header:not(.muze-rtl) .navbar,
  body.container.double-header:not(.muze-rtl) .navbar {
    margin-left: 0;
  }
  body.container.top-header.festive-template:not(.muze-rtl)
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end,
  body.container.double-header.festive-template:not(.muze-rtl)
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-right: 1rem !important;
  }
  body.container.top-header.festive-template.muze-rtl
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end,
  body.container.double-header.festive-template.muze-rtl
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-left: 1rem !important;
  }
  body.container.festive-template:not(.muze-rtl) .main-content .p-xxl-5 {
    padding-right: 1rem !important;
  }
  body.container.pricing-template .after-header ~ .px-xxl-5,
  body.container.help-center-template .after-header ~ .px-xxl-5 {
    padding-right: 1rem !important;
  }
  body.container.pricing-template .btn-soft-primary,
  body.container.help-center-template .btn-soft-primary {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  body.container.chat-template .muze-chats .px-xxl-5 {
    padding-right: 1rem !important;
  }
  body.container.help-center-template .h-100.px-lg-5,
  body.container.pricing-template .h-100.px-lg-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media screen and (max-width: 1199px) {
  footer .navbar li {
    padding-left: 1.5625rem;
  }
  .get-started-two {
    position: static;
    text-align: right;
    margin-bottom: -1.875rem;
  }
  .title-box {
    min-height: initial;
  }
  .menu-close {
    display: none;
  }
  .navbar-toggler[aria-expanded="true"] .menu-icon {
    display: none;
  }
  .navbar-toggler[aria-expanded="true"] .menu-close {
    display: block;
  }
  .chat-template .nav.nav-pills {
    margin-top: 0.0625rem;
  }
  .chat-template .nav.nav-pills .nav-link {
    padding: 0.3rem 0.9rem;
    font-size: 0.875rem;
  }
  .has-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 17.5rem;
  }
  .has-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: calc(100% - 17.5rem);
  }
  .chat-list {
    height: calc(100vh - 10.68rem);
  }
  .chat-list a h6,
  .chat-list a .h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.5rem;
  }
  .chat-items {
    height: calc(100vh - 19.75rem);
  }
  .message-box {
    padding: 0.9375rem;
  }
  .message-box p {
    font-size: 0.875rem;
    line-height: 1.6;
  }
  .message-files ul li {
    width: 25%;
  }
  .top-header.sidebar-icons .navbar-brand img:not(.white-logo),
  .top-header.sidebar-compact .navbar-brand img:not(.white-logo) {
    display: -ms-flexbox;
    display: flex;
  }
  .top-header.sidebar-icons .top-header-nav,
  .top-header.sidebar-compact .top-header-nav {
    opacity: 1;
    visibility: visible;
  }
  .top-header.sidebar-icons .header-fixed,
  .top-header.sidebar-compact .header-fixed {
    left: 0;
  }
  .top-header.sidebar-icons .main-content,
  .top-header.sidebar-compact .main-content {
    margin-left: 0;
  }
  .top-header.sidebar-icons .back-arrow img,
  .top-header.sidebar-compact .back-arrow img {
    display: none;
  }
  .top-header.sidebar-icons .back-arrow img.menu-close,
  .top-header.sidebar-compact .back-arrow img.menu-close {
    display: block;
  }
  .top-header .navbar-brand.d-lg-none {
    display: inherit !important;
  }
  .top-header .back-arrow.d-lg-none {
    display: block !important;
  }
  .top-header .top-header-nav {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 5.75rem;
    left: 0;
    right: 0;
    padding: 0.625rem 0;
    transition: all ease 0.4s;
    border-bottom: 0.0625rem solid rgba(108, 117, 125, 0.125);
  }
  .top-header .top-header-nav .nav-item .nav-link {
    padding: 0.6875rem 1.375rem;
  }
  .top-header.sidebar-menu .top-header-nav {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .top-header.sidebar-menu .muze-hamburger img {
    display: none;
  }
  .top-header.sidebar-menu .muze-hamburger img.menu-close {
    display: block;
  }
  .top-header .navbar {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-left: 0;
  }
  .top-header .navbar .nav-item .collapse-box .nav {
    padding: 0;
  }
  .top-header .muze-hamburger {
    display: block !important;
  }
  .top-header .header-body > .row {
    margin-left: calc(var(--bs-gutter-x) / -2) !important;
  }
  .double-header .navbar {
    padding-left: 0;
  }
  .double-header .chat-items {
    height: calc(100vh - 23.7rem);
  }
  .double-header .chat-list {
    height: calc(100vh - 14.7rem);
  }
  .muze-rtl.top-header .navbar .nav-item .collapse-box {
    left: auto;
    right: 0;
  }
  .muze-rtl.top-header .header-body > .row {
    margin-right: calc(var(--bs-gutter-x) / -2);
  }
  .muze-rtl.top-header.muze-dark-mode .navbar {
    border: none;
    margin-right: 0;
    padding-right: 0;
  }
  .muze-rtl .customize-body .form-check-inline {
    margin-right: 0;
  }
  .muze-rtl footer .navbar {
    padding-left: 0;
  }
  .muze-rtl footer .navbar li {
    padding-right: 1.5rem;
  }
  body.container.top-header .header-body > .row {
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  body.container.top-header .main-content .header .container-fluid,
  body.container.top-header .main-content .header .container-sm,
  body.container.top-header .main-content .header .container-md,
  body.container.top-header .main-content .header .container-lg,
  body.container.top-header .main-content .header .container-xl,
  body.container.top-header .main-content .header .container-xxl {
    max-width: 60rem;
  }
  body.container.double-header .main-content .header .container-fluid,
  body.container.double-header .main-content .header .container-sm,
  body.container.double-header .main-content .header .container-md,
  body.container.double-header .main-content .header .container-lg,
  body.container.double-header .main-content .header .container-xl,
  body.container.double-header .main-content .header .container-xxl {
    max-width: 60rem;
  }
}

@media screen and (min-width: 992px) {
  /*1. Muze Header Versions CSS */
  /* 1.2  Muze Top Header CSS */
  .top-header .header-body > .row,
  .double-header .header-body > .row {
    margin-left: 0;
  }
  /* 1.3  Muze Double Header CSS */
  .double-header .header-search {
    display: block;
  }
  .double-header .header-search .input-group {
    background-color: transparent !important;
    border: none !important;
    border-left: 0.0625rem solid #495057 !important;
    border-radius: 0 !important;
    padding-left: 1.5625rem !important;
  }
  .double-header .header-search .input-group .form-control {
    background-color: transparent;
    color: #ced4da;
  }
  .double-header
    .header-search
    .input-group
    .form-control::-webkit-input-placeholder {
    color: #ced4da;
  }
  .double-header .header-search .input-group .form-control::-moz-placeholder {
    color: #ced4da;
  }
  .double-header
    .header-search
    .input-group
    .form-control:-ms-input-placeholder {
    color: #ced4da;
  }
  .double-header .header-search .input-group .form-control:-moz-placeholder {
    color: #ced4da;
  }
  .double-header .header-search .input-group img {
    -webkit-filter: brightness(3);
    filter: brightness(3);
  }
  .get-started-title {
    font-size: 1.75rem;
  }
  .muze-icon {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .header-search {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    min-width: 100%;
    z-index: 9;
    padding: 0.9375rem;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08) !important;
  }
  .header-search form {
    width: calc(100% - 1.875rem);
  }
  .search-open {
    overflow: hidden;
    position: relative;
    z-index: 9;
  }
  .search-open:before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(18, 17, 17, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  .search-open .header-search {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    z-index: 11;
  }
  .header-fixed {
    left: 0;
  }
  .navbar-vertical.navbar-expand-lg {
    width: 100%;
    max-width: 15.625rem;
    position: fixed;
    left: 0;
    background-color: #fff;
    top: 4.54rem;
    height: calc(100vh - 4.54rem);
    z-index: 9;
    box-shadow: 0.125rem 0.5rem 1rem rgba(0, 0, 0, 0.04);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all ease 0.4s;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    height: calc(100vh - 12.5rem);
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.775rem 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav-link {
    padding-left: 1.8rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-item .nav .nav-link {
    padding-left: 2.8rem;
  }
  .navbar-vertical .navbar-collapse {
    height: 100%;
  }
  .navbar-vertical .navbar-vertical-footer {
    padding: 0.375rem 0.9375rem;
  }
  .navbar-vertical .simplebar-track {
    right: -0.875rem;
  }
  .sidebar-icons .navbar-vertical {
    max-width: 5rem;
  }
  .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav {
    height: calc(100vh - 10.625rem);
  }
  .sidebar-icons .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item {
    margin-top: 1.25rem;
  }
  .sidebar-icons .navbar-vertical .simplebar-track {
    right: 0;
  }
  .sidebar-icons .header-fixed {
    left: 0;
  }
  .sidebar-icons .navbar-brand img:not(.white-logo) {
    display: block;
  }
  .sidebar-icons .main-content {
    margin-left: 0;
  }
  .sidebar-compact .navbar-vertical.navbar-expand-lg .navbar-nav {
    height: calc(100vh - 11.5625rem);
  }
  .sidebar-compact .main-content {
    margin-left: 0;
  }
  .sidebar-compact .header-fixed {
    left: 0;
  }
  .sidebar-compact .navbar-brand img:not(.white-logo) {
    display: block;
  }
  .sidebar-compact .simplebar-track {
    right: 0;
  }
  .sidebar-menu {
    overflow: hidden;
  }
  .sidebar-menu .navbar-vertical {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    top: 4.29rem;
    height: calc(100vh - 4.29rem);
  }
  .sidebar-menu .muze-hamburger img {
    display: none;
  }
  .sidebar-menu .muze-hamburger .menu-close {
    display: block;
  }
  .muze-rtl.top-header .header-body .icon-blue-hover.ps-0 {
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
  }
  .muze-rtl.top-header .header-body .notification {
    margin-right: 1.5rem !important;
  }
  .muze-rtl.top-header .header .customize-btn span {
    padding-right: 0 !important;
  }
  .muze-rtl.top-header
    .top-header-nav.navbar
    .nav-item
    .nav-link[data-bs-toggle="collapse"]:after {
    margin-left: 0;
    margin-right: auto;
  }
  .muze-rtl.top-header .top-header-nav.navbar .nav-item .collapse-box {
    padding-left: 0;
  }
  .muze-rtl.top-header .after-header {
    margin-top: 6.0625rem;
  }
  .muze-rtl.double-header .header-body .notification {
    margin-right: 1.5rem !important;
  }
  .muze-rtl.double-header.search-open .header-search .input-group {
    border-color: #dee2e6 !important;
    border-left: 0.0625rem solid #dee2e6 !important;
  }
  .muze-rtl.double-header .navbar .nav-item hr.bg-gray-50 {
    background-color: transparent !important;
  }
  .muze-rtl.double-header
    .navbar
    .nav-item
    .nav-link[data-bs-toggle="collapse"]:after {
    margin-left: 0;
    margin-right: auto;
  }
  .muze-rtl.double-header .navbar .nav-item .collapse-box {
    padding-left: 0;
  }
  .muze-rtl.sidebar-compact .main-content,
  .muze-rtl.sidebar-icons .main-content {
    margin-right: 0;
  }
  .muze-rtl.sidebar-compact .header-fixed,
  .muze-rtl.sidebar-icons .header-fixed {
    right: 0;
  }
  .muze-rtl .main-content {
    margin-right: 0;
  }
  .muze-rtl .header-fixed {
    right: 0;
  }
  .muze-rtl .header-body .notification {
    margin-right: 1.5rem !important;
  }
  .muze-rtl .navbar-vertical.navbar-expand-lg {
    left: auto;
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .muze-rtl
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav
    .nav-item
    .nav-link {
    padding-left: 0;
    padding-right: 1.8rem;
  }
  .muze-rtl
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav
    .nav-item
    .nav
    .nav-link {
    padding-left: 0;
    padding-right: 1.8rem;
  }
  .muze-rtl.sidebar-menu .navbar-vertical {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .muze-dark-mode .muze-search > svg path {
    fill: #fff;
  }
  .muze-dark-mode .header .navbar-brand img {
    display: none;
  }
  .muze-dark-mode .header .navbar-brand img.white-logo {
    display: block;
  }
  .muze-dark-mode .navbar-vertical.navbar-expand-lg {
    padding: 0;
  }
  .muze-dark-mode .navbar-vertical.navbar-expand-lg .navbar-collapse {
    margin: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #1e1e1e;
  }
  .muze-dark-mode.search-open .muze-search > svg path {
    fill: #1e1e1e;
  }
  .muze-dark-mode.search-open .muze-search:hover > svg path {
    fill: #0da2f5;
  }
  .muze-dark-mode.search-open
    .header-search
    form
    .input-group.bg-white.border-gray-300 {
    background-color: #fff !important;
    border: 0.0625rem solid #dee2e6 !important;
  }
  .muze-dark-mode.search-open .header-search .form-control {
    background-color: #fff !important;
  }
  .signout i {
    display: none;
  }
  .muze-icon,
  .back-arrow {
    display: none;
  }
  .main-content {
    margin-left: 0;
    margin-top: 4.5625rem;
  }
  .navbar {
    padding: 0.75rem 1rem;
    border-bottom: 0.0625rem solid rgba(108, 117, 125, 0.125);
  }
  .navbar .dropdown-toggle:after {
    display: none;
  }
  .navbar.navbar-light .navbar-toggler {
    border-color: transparent;
  }
  .navbar-light .navbar-collapse:before {
    border-top-color: #e3ebf6;
  }
  .avatar.avatar-sm {
    width: 2.25rem;
    min-width: 2.25rem;
    height: 2.25rem;
  }
  .avatar.avatar-circle .avatar-sm-status {
    bottom: -0.0625rem;
    right: -0.0625rem;
    width: 0.75rem;
    min-width: 0.75rem;
    height: 0.75rem;
  }
  .overlay-center .btn {
    min-width: 10.9375rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .check-list .form-check-input {
    margin-right: 0;
  }
  .check-list .form-check-label {
    margin-left: 1rem !important;
  }
  .double-header.search-open:before {
    z-index: 8;
  }
  .double-header.search-open .header-search {
    display: -ms-flexbox;
    display: flex;
  }
  .double-header.search-open .muze-search svg path {
    fill: #1e1e1e;
  }
  .double-header.sidebar-menu .double-header-nav,
  .double-header.sidebar-compact .double-header-nav {
    opacity: 1;
    visibility: visible;
    display: block;
  }
  .double-header .header-search {
    display: none;
  }
  .double-header .main-content {
    margin-top: 5.0625rem;
  }
  .double-header .muze-search svg path {
    fill: #fff;
  }
  .double-header .muze-hamburger img {
    -webkit-filter: brightness(10);
    filter: brightness(10);
  }
  .double-header .double-header-nav {
    padding: 0;
    transition: all ease 0.4s;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  .double-header .double-header-nav .navbar {
    padding-left: 0;
  }
  .double-header .navbar .nav-item .nav-link[data-bs-toggle="collapse"]:after {
    margin-left: auto;
  }
  .double-header .navbar .nav-item .collapse-box {
    position: static;
    min-width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding-left: 1.875rem;
  }
  .double-header .navbar .nav-item .collapse-box .nav {
    padding: 0;
  }
  .double-header .chat-items {
    height: calc(100vh - 18.6rem);
  }
  .double-header .chat-list {
    height: calc(100vh - 9.6rem);
  }
  .top-header .navbar {
    padding: 0;
  }
  .top-header .top-header-nav {
    top: 4.3125rem;
  }
  .top-header
    .top-header-nav.navbar
    .nav-item
    .nav-link[data-bs-toggle="collapse"]:after {
    margin-left: auto;
  }
  .top-header .top-header-nav.navbar .nav-item .collapse-box {
    position: static;
    min-width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding-left: 1.875rem;
  }
  .top-header .main-content {
    margin-top: 5.0625rem;
  }
  .chat-items {
    height: calc(100vh - 17.9rem);
  }
  .chat-list {
    height: calc(100vh - 9rem);
  }
  .muze-rtl.double-header .header-body > .row {
    margin-left: 0;
    margin-right: calc(var(--bs-gutter-x) / -2);
  }
  .muze-rtl .form-check .form-check-input {
    margin-right: -1.5em;
  }
  body.container:not(.muze-rtl) .main-content .px-xxl-5,
  body.container:not(.muze-rtl) .main-content .p-xxl-5 {
    padding-left: 0 !important;
  }
  body.container.muze-rtl .main-content .px-xxl-5,
  body.container.muze-rtl .main-content .p-xxl-5 {
    padding-right: 0 !important;
  }
  body.container:not(.top-header):not(.top-header)
    .navbar-vertical.navbar-expand-lg {
    background-color: #fff;
    border-left: none;
  }
  body.container.double-header .main-content .header .container-fluid,
  body.container.double-header .main-content .header .container-sm,
  body.container.double-header .main-content .header .container-md,
  body.container.double-header .main-content .header .container-lg,
  body.container.double-header .main-content .header .container-xl,
  body.container.double-header .main-content .header .container-xxl,
  body.container.top-header .main-content .header .container-fluid,
  body.container.top-header .main-content .header .container-sm,
  body.container.top-header .main-content .header .container-md,
  body.container.top-header .main-content .header .container-lg,
  body.container.top-header .main-content .header .container-xl,
  body.container.top-header .main-content .header .container-xxl {
    max-width: 45rem;
  }
  body.container.double-header .header:before {
    height: 5.0625rem;
  }
  body.container.double-header
    .double-header-nav
    .navbar-expand-lg
    .navbar-nav {
    margin: 0;
  }
  body.container.festive-template:not(.muze-rtl) .main-content .p-xxl-5 {
    padding-right: 1rem !important;
  }
  body.container.festive-template:not(.muze-rtl)
    .main-content
    .col-md-5.col-xxl-3
    .p-xxl-5,
  body.container.festive-template:not(.muze-rtl)
    .main-content
    .col-md-5.col-xxl-3
    .px-xxl-5 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  body.container.pricing-template .after-header ~ .px-xxl-5,
  body.container.help-center-template .after-header ~ .px-xxl-5 {
    padding-left: 1rem !important;
  }
  body.container.chat-template .muze-chats .px-xxl-5 {
    padding-left: 1rem !important;
  }
  .signup-template h1.display-3,
  .signup-template .display-3.h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 767px) {
  .card-table th {
    padding: 0.8125rem 0.9375rem;
  }
  .card-table td {
    padding: 0.9375rem;
  }
  .get-startedtwo {
    position: static;
  }
  .get-started-img {
    margin-bottom: -16.25rem;
    bottom: 0;
    position: relative;
    right: 0;
  }
  footer {
    text-align: center;
    padding-top: 0.625rem;
  }
  footer .navbar {
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
  }
  footer .navbar li {
    padding-left: 0;
    padding-right: 0.9375rem;
  }
  .notification .status {
    position: absolute;
    top: -0.125rem;
    right: -0.25rem;
  }
  .title-box {
    min-height: auto;
  }
  .get-started-two {
    position: static;
    margin-bottom: -1.875rem;
  }
  .invoice-template .card-table td {
    padding: 1.25rem 0.9375rem;
  }
  .login-form {
    max-width: 100%;
  }
  .signup-template .display-3 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
  .signup-template .cover-fit {
    height: auto;
  }
  .user-profile-template .avatar.avatar-lg {
    width: 3.125rem;
    min-width: 3.125rem;
    height: 3.125rem;
  }
  .user-profile-template .avatar-lg-status {
    width: 0.875rem;
    height: 0.875rem;
    min-width: 0.875rem;
  }
  .group-cards .title-box {
    min-height: auto;
  }
  .lh-lg {
    line-height: 1.8 !important;
  }
  .pricing-template .top-less {
    margin-top: -6.875rem;
  }
  .help-center-template form .btn {
    width: 100%;
  }
  .ql-container.ql-snow {
    height: 7.5rems;
  }
  .step-list h5,
  .step-list .h5 {
    font-size: 1rem;
  }
  .new-projects-template form .circle-xl {
    width: 3.75rem;
    height: 3.75rem;
  }
  .activity-list .list-group-item:not(:last-child):before {
    left: 2.6875rem;
  }
  .chat-template .circle-lg {
    width: 2.375rem;
    height: 2.375rem;
    min-width: 2.375rem;
  }
  .chat-template .circle-lg svg {
    width: 1rem;
  }
  .chat-template .col-auto.has-xxl-4 {
    width: 100%;
  }
  .chat-template .col.has-xxl-8 {
    width: 100%;
  }
  .chat-template .nav.nav-pills {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow: auto;
  }
  .has-xxl-4 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .has-xxl-8 {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
  }
  .chat-list {
    height: auto;
    border-bottom: 0.0625rem solid #e9ecef;
  }
  .chat-list a {
    padding: 0.625rem;
    border-left: 0;
    border-bottom: 0.3125rem solid transparent;
  }
  .chat-list a.active {
    background-color: transparent;
  }
  .chat-list .media .media-body {
    display: none;
  }
  .chat-list .simplebar-content {
    display: -ms-flexbox;
    display: flex;
    overflow-x: auto;
  }
  .chat-items {
    height: calc(100vh - 16.5rem);
    margin-bottom: 7.5rem;
  }
  .message-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
  }
  .message-footer .form-control {
    height: 3.125rem;
    padding: 0;
  }
  .message-footer a:not(.btn) {
    margin-right: 1.25rem;
  }
  .chat-item {
    padding: 0.375rem 0;
  }
  .message-box {
    padding: 0.625rem;
  }
  .message-box:before {
    border-bottom: 0.875rem solid transparent;
    bottom: 21%;
  }
  .attachment {
    padding: 0.5rem 0.9375rem;
    font-size: 0.875rem;
  }
  .message-box p {
    font-size: 0.8125rem;
  }
  .project-management-template .get-startedthree {
    position: relative;
    margin: 1.25rem 0;
  }
  .calendar-template .fc-toolbar.fc-header-toolbar {
    position: relative;
    padding-top: 3rem;
  }
  .calendar-template .fc-toolbar-chunk .fc-toolbar-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    text-align: center;
    font-size: 1.375rem;
  }
  .nav-tabs.nav-tabs-line .nav-link {
    padding: 0.65rem 0.75rem;
    font-size: 0.875rem;
  }
  .double-header .double-header-nav {
    top: 4.25rem;
  }
  .double-header .double-header-nav .navbar {
    padding-left: 0;
  }
  .double-header .customize-btn.btn-lg,
  .double-header .btn-group-lg > .customize-btn.btn {
    line-height: 1.28rem;
    padding: 0.5rem 0.875rem;
  }
  .double-header .customize-btn span {
    font-size: 0;
    padding-left: 0 !important;
  }
  .double-header .chat-items {
    height: calc(100vh - 21.5rem);
  }
  .double-header .chat-list {
    height: auto;
  }
  .top-header .customize-btn.btn-lg,
  .top-header .btn-group-lg > .customize-btn.btn {
    line-height: 1.28rem;
    padding: 0.5rem 0.875rem;
  }
  .top-header .customize-btn span {
    font-size: 0;
    padding-left: 0 !important;
  }
  .muze-rtl .header-body .notification .status {
    right: auto;
    left: -0.25rem;
  }
  .muze-rtl .header .customize-btn .ps-2 {
    padding-right: 0 !important;
  }
  .muze-rtl.top-header .after-header {
    margin-top: 4.0625rem;
  }
  .muze-rtl footer .navbar li {
    padding: 0.3125rem 1rem;
  }
  .muze-rtl.festive-template .col-auto.ps-0.ps-md-3 {
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
  }
  .muze-rtl .activity-list .list-group-item:not(:last-child):before {
    right: 2.6rem;
  }
  .muze-dark-mode.chat-template .chat-list a.active {
    background-color: transparent;
  }
  .muze-dark-mode .message-footer {
    background-color: #1a1919;
  }
  .muze-dark-mode .nav-tabs.nav-tabs-line .nav-link:not(.active) {
    border-color: #343a40;
  }
  .list-group .list-group-item .avatar.avatar-circle .avatar-sm-status {
    right: 0.125rem;
    bottom: 0.125rem;
  }
  body.container {
    max-width: 100%;
    padding: 0;
  }
  body.container:not(.muze-rtl) .main-content .px-xxl-5,
  body.container:not(.muze-rtl) .main-content .p-xxl-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  body.container.double-header .main-content .header:before {
    height: 4.6875rem;
  }
  body.container.double-header .main-content .header .container-fluid,
  body.container.double-header .main-content .header .container-sm,
  body.container.double-header .main-content .header .container-md,
  body.container.double-header .main-content .header .container-lg,
  body.container.double-header .main-content .header .container-xl,
  body.container.double-header .main-content .header .container-xxl {
    max-width: 100%;
  }
  body.container.double-header .main-content .header .px-xxl-5 {
    padding-right: 0 !important;
  }
  body.container.double-header.festive-template:not(.muze-rtl)
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-left: 1rem !important;
  }
  body.container.double-header.festive-template.muze-rtl
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-right: 1rem !important;
  }
  body.container.top-header .main-content .header .px-xxl-5 {
    padding-right: 0 !important;
  }
  body.container.top-header.festive-template:not(.muze-rtl)
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-left: 1rem !important;
  }
  body.container.top-header.festive-template.muze-rtl
    .main-content
    .col-xxl-9
    > .p-xxl-5.border-end {
    padding-right: 1rem !important;
  }
  body.container.muze-rtl .main-content .px-xxl-5,
  body.container.muze-rtl .main-content .p-xxl-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  body.container.project-management-template
    .main-content
    .bg-gray-100.p-md-5
    .col-xl-6 {
    width: 100%;
  }
  body.container.project-management-template:not(.muze-rtl) .get-startedthree {
    right: -6.25rem;
  }
  body.container.festive-template:not(.muze-rtl)
    .main-content
    .col-md-5.col-xxl-3
    .p-xxl-5,
  body.container.festive-template:not(.muze-rtl)
    .main-content
    .col-md-5.col-xxl-3
    .px-xxl-5 {
    padding-right: 1rem !important;
  }
  body.container .muze-chats {
    border-right: none !important;
  }
}

@media screen and (max-width: 639px) {
  .display-4 {
    font-size: 2.5rem;
  }
  .customize-sidebar {
    width: 25rem;
  }
  .grid-option > .dropdown-menu {
    min-width: 20.625rem;
  }
  footer .fs-16 {
    font-size: 0.875rem !important;
  }
  footer .navbar li {
    padding-top: 0;
  }
  footer .navbar li a {
    font-size: 0.875rem;
  }
  .get-started-img {
    margin-bottom: -11.875rem;
    padding: 0.625rem;
  }
  .get-started-two {
    position: static;
    margin-bottom: -1.875rem;
  }
  .simple-login-form {
    width: 100%;
    padding: 2.5rem;
    margin-bottom: 0.9375rem;
  }
  .signup-header {
    padding: 1.875rem 0.9375rem 15.625rem;
  }
  .message-files ul li {
    width: 33.333%;
  }
  .new-projects-template form span.text-muted {
    font-size: 0.8125rem;
  }
  .project-management-template .get-startedthree {
    right: -6.25rem;
    margin-top: 0.625rem;
  }
  .double-header .header .customize-btn,
  .top-header .header .customize-btn {
    position: fixed;
    bottom: 1.25rem;
    right: 1.25rem;
    z-index: 9;
    border-radius: 50%;
    width: 2.625rem;
  }
  .double-header .header .customize-btn.btn-lg,
  .double-header .header .btn-group-lg > .customize-btn.btn,
  .top-header .header .customize-btn.btn-lg,
  .top-header .header .btn-group-lg > .customize-btn.btn {
    padding: 0.5rem 0.675rem;
  }
  .top-header .main-content,
  .double-header .main-content {
    margin-top: 4.3125rem;
  }
  .muze-rtl.top-header .header .customize-btn,
  .muze-rtl.double-header .header .customize-btn {
    margin-right: 0 !important;
  }
  body.container.top-header .main-content .header .customize-btn,
  body.container.double-header .main-content .header .customize-btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 479px) {
  .display-4 {
    font-size: 2.1rem;
  }
  .customize-sidebar {
    width: 100%;
  }
  .customize-body {
    height: calc(100vh - 15rem);
  }
  .sidebar-style img {
    max-width: 5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-item .nav-link {
    font-size: 0.75rem;
  }
  .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .collapse-box
    > .nav
    .nav-link {
    font-size: 0.75rem;
  }
  .sidebar-icons
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    .nav-link {
    font-size: 0;
  }
  .sidebar-icons
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .collapse-box
    > .nav
    .nav-link {
    font-size: 0.75rem;
  }
  .sidebar-compact .navbar-vertical.navbar-expand-lg {
    max-width: 7.5rem;
  }
  .sidebar-compact
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .nav-link
    span:not(.docs-version) {
    font-size: 0.75rem;
  }
  .sidebar-compact
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .collapse-box {
    left: 7.5rem;
  }
  .sidebar-compact
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .collapse-box
    > .nav {
    min-width: 11.875rem;
  }
  .sidebar-compact
    .navbar-vertical.navbar-expand-lg
    .navbar-nav
    .nav-item
    > .collapse-box
    > .nav
    .nav-link {
    font-size: 0.75rem;
  }
  .sidebar-compact .signout .btn-lg,
  .sidebar-compact .signout .btn-group-lg > .btn {
    padding: 0.625rem 0.9375rem;
    height: 3rem;
  }
  .grid-option > .dropdown-menu {
    min-width: 17.5rem;
  }
  .grid-option .dropdown-body .dropdown-item {
    padding: 0.9375rem 0.625rem;
  }
  .profile-dropdown > .dropdown-menu {
    min-width: 13.125rem;
  }
  .signup-template .display-3 {
    font-size: 2.1rem;
    line-height: 2.7rem;
  }
  .simple-login-form {
    padding: 1.25rem;
  }
  .pricing-template p.fs-20.lh-lg {
    font-size: 1.125rem;
    line-height: 1.6 !important;
  }
  .all-projects-template .avatar {
    min-width: 2.25rem;
    height: 2.25rem;
    width: 2.25rem;
  }
  .chat-header h4,
  .chat-header .h4 {
    font-size: 1.125rem;
    line-height: 1.6rem;
  }
  .chat-item-inner .chat-message {
    padding-top: 0;
  }
  .message-files ul li {
    width: 50%;
    margin-bottom: 0.625rem;
  }
  .chat-template .btn-group-xl > .btn,
  .chat-template .btn-xl {
    height: 2.75rem;
    font-size: 0.875rem;
  }
  .muze-chats .avatar {
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
  }
  .muze-chats .avatar-status {
    min-width: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
  }
  .file-manager-template h6,
  .file-manager-template .h6 {
    font-size: 0.875rem;
  }
  .file-manager-template .dropdown.end-0 {
    right: 0.3125rem !important;
  }
  .file-manager-template .dropdown.top-0 {
    top: 0.3125rem !important;
  }
  .calendar-template .fc-toolbar.fc-header-toolbar {
    display: block;
    padding-top: 1.75rem;
    text-align: center;
  }
  .calendar-template .fc-toolbar-chunk {
    margin-top: 0.625rem;
  }
  .sidebar-style .form-check-label img {
    max-width: 5rem;
  }
}

@media screen and (max-width: 420px) {
  .header .navbar-brand img {
    max-width: 4.0625rem;
  }
  .header .icon-blue-hover .muze-search svg {
    max-width: 1rem;
  }
  .header .icon-blue-hover .dropdown a.ms-4 {
    margin-left: 1rem !important;
  }
  .header .icon-blue-hover .dropdown a svg {
    max-width: 1rem;
  }
  .muze-rtl.top-header .header .icon-blue-hover .dropdown a.ms-4,
  .muze-rtl.double-header .header .icon-blue-hover .dropdown a.ms-4 {
    margin-right: 1rem !important;
    margin-left: 0 !important;
  }
  .muze-rtl.top-header .after-header,
  .muze-rtl.double-header .after-header {
    margin-top: 4.5625rem;
  }
  .muze-rtl.double-header .after-header {
    margin-top: 0;
  }
  .muze-rtl.muze-dark-mode.top-header .after-header {
    margin-top: 4.5625rem;
  }
  .muze-rtl .form-check-inline {
    margin-right: 0;
  }
  .muze-rtl .header .icon-blue-hover .dropdown a.ms-4 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }
  .muze-rtl .message-footer a:not(.btn) {
    margin-left: 1.5rem;
  }
  .muze-rtl.billing-templete .btn-outline-dark.me-3 {
    margin-left: 0 !important;
  }
  .muze-rtl.billing-templete
    .btn-outline-dark.me-3
    ~ .btn.btn-lg.btn-primary.mt-2,
  .muze-rtl.billing-templete
    .btn-group-lg
    > .btn-outline-dark.me-3
    ~ .btn.btn-primary.mt-2 {
    margin-right: 0.5rem;
  }
  body.container.top-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
    margin-left: 1rem !important;
  }
  body.container.double-header:not(.muze-rtl) .icon-blue-hover .ms-xxl-5 {
    margin-left: 1rem !important;
  }
  body.container.double-header .main-content .header:before {
    height: 4.3125rem;
  }
}
