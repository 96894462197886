html {
  --color-primary: #0da2f5;
  --color-primary-hover: #0da2f580;
  --color-light-gray: #e9eaec;
  --color-mid-gray: #777e90;
  --color-secondary: #ff8c45;
}
@import url("https://rsms.me/inter/inter.css");

body {
  margin: 0;
  font-family: Inter, sans-serif;
  color: #0d1522;
}

html,
body,
#root {
  height: 100vh;
  display: flex;
  flex-flow: column;
  background-color: #f0f9ff;
}

.pac-container {
  z-index: 10000 !important;
}

.form-control:disabled {
  background-color: #f4f4f4 !important;
  border-color: rgb(206, 212, 218) !important;
  color: #76808f !important;
  cursor: not-allowed;
}

nav + div {
  padding-bottom: 72px !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: grey;
}

svg {
  flex-shrink: 0;
}
